import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BsCalendarDate,
  BsBuilding,
  BsFillPersonCheckFill,
  BsPersonXFill,
  BsUpcScan,
} from "react-icons/bs";
import InfoCard from "../../components/cards/InfoCard";
import { MdArrowBack, MdEmail, MdFlag, MdHouse, MdPhone } from "react-icons/md";
import WorkPermitCard from "../../components/cards/WorkPermitCard";
import {
  GET_APPLICANT_REQUEST_BYID,
  GET_EMPLOYEES,
  GET_EXPATRIATE_BYID,
  GET_VISA_TYPE,
  GET_WORK_PERMIT_BYID,
} from "../../graph-query/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import StatusCard from "../../components/StatusCard";
import { roles } from "../../utils/roles";
import { CSelectField, FormProvider } from "../../components/Forms/Fields";
import { useLocalStore } from "../../store";
import { useForm } from "react-hook-form";
import CButton from "../../components/Forms/CButton";
import { INSERT_ASSIGN_EMPLOYEE } from "../../graph-query/mutation";
import { toast } from "react-toastify";
import { Can } from "../../permission/Can";
import { PROFILE_IMAGE_ID } from "../../utils/constants";
import { GetLatestProfilePic } from "../../utils/utilityFunctions";
import { getImageUrl } from "../../components/common/imageUtils";

const ApplicantProfile = () => {
  const router = useNavigate();
  const [visaType, setVisaType] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);

  const [applicantData, setApplicantData] = useState([]);
  const { id } = useParams();
  const { employees, currentRole } = useLocalStore();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [getEmployee, { loading: empLoading, error: empError }] = useLazyQuery(
    GET_EMPLOYEES,
    {
      onCompleted: (data) => {
        setEmployeeData(data?.employees);
      },
    }
  );

  const [getData, { error, loading }] = useLazyQuery(GET_WORK_PERMIT_BYID, {
    variables: {
      id: id,
    },
    onCompleted: (data) => {
      console.log("applicant detail: ", data);
      setApplicantData(data?.expatriate_work_permits[0]);
      //groupDocument(data?.expatriate_work_permits[0]?.expatriate_work_permit_documents);
      // getVisaType({ variables: { id: data?.expatriates[0]?.visa_type } });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [manualAssign, { loading: assignLoading }] = useMutation(
    INSERT_ASSIGN_EMPLOYEE,
    {
      onCompleted: (d) => {
        toast.success("Assigned Successfully");
        reset();
        getData({ fetchPolicy: "network-only" });
      },
    }
  );

  // const [getVisaType, {}] = useLazyQuery(GET_VISA_TYPE, {
  //   onCompleted: (data) => {
  //     setVisaType(data?.base_visa_types[0]);
  //   },
  // });

  const handleManualAssign = (e) => {
    console.log(e);
    manualAssign({
      variables: {
        permitID: applicantData?.id,
        empID: e?.user_id,
      },
    });
  };
 
  useEffect(() => {
    if (currentRole === roles?.WP_DESK_HANDLER) {
      getEmployee();
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (applicantData) {
        const url = await getImageUrl(applicantData);
        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [applicantData]);

  return (
    <div className="flex gap-6">
      <div className="w-2/3 space-y-6">
        <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
          <button
            onClick={() => router(-1)}
            className="bg-gray px-3 py-2 border-2 rounded-md flex gap-2 items-center"
          >
            <MdArrowBack /> Back
          </button>
          <h2 className="text-primary capitalize font-semibold">
            Work Permit Details
          </h2>
          <div className="flex gap-4 items-start">
            <div className="flex flex-col gap-4 w-2/5">
              <div className="rounded bg-N99/30 p-3 space-y-1">
                <p className="text-sm text-N70 capitalize">EMR number</p>
                <h3 className="2xl:text-lg text-primary font-semibold tracking-wider">
                  {applicantData?.emr_number || "---"}
                </h3>
              </div>
              <div className="rounded bg-N99/30 p-3 space-y-1">
                <p className="text-sm text-N70 capitalize">tracking Number</p>
                <h3 className="text-lg text-primary font-semibold tracking-wider">
                  {applicantData?.tracking_number || "---"}
                </h3>
              </div>
            </div>
            <div className="w-3/5 rounded-md text-sm bg-N99/30 p-3 space-y-4">
              <div className="flex 2xl:w-11/12 mx-auto items-center gap-4 justify-between">
                <h3 className="font-medium">Work Expat. Entry Date</h3>
                <p className="font-semibold">Dec 4, 2020</p>
              </div>
              <div className="flex 2xl:w-11/12 mx-auto items-center gap-4 justify-between">
                <div className="flex items-center gap-2 text-N50">
                  <BsCalendarDate />
                  <p>Employment Start Date</p>
                </div>
                <p className="font-semibold">
                  {applicantData && applicantData?.start_date}
                </p>
              </div>
              <div className="flex 2xl:w-11/12 mx-auto items-center gap-4 justify-between">
                <div className="flex items-center gap-2 text-N50">
                  <BsCalendarDate className="text-primary" />
                  <p>Employment Expired Date</p>
                </div>
                <p className="font-semibold">
                  {applicantData && applicantData?.end_date}
                </p>
              </div>
            </div>
          </div>
          <div className=" space-y-2 text-sm">
            <div className="flex xl:w-1/2 items-center gap-4 justify-between">
              <h3 className="text-N50">Salary</h3>
              <p className="font-medium text-green50">
                {(applicantData && applicantData?.salary) || "---"} ETB
              </p>
            </div>
            <div className="flex xl:w-1/2 items-center gap-4 justify-between">
              <h3 className="text-N50">Allowance / Tip</h3>
              <p className="font-medium text-N50">
                {(applicantData && applicantData?.allowance) || "---"} ETB
              </p>
            </div>
            <div className="flex xl:w-1/2 items-center gap-4 justify-between">
              <h3 className="text-N50">Intended Position</h3>
              <p className="font-medium text-N30">
                {(applicantData &&
                  applicantData?.profession_title?.name_json?.en) ||
                  "---"}
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
          <div className="flex gap-8">
            <div className="w-1/2 space-y-3 flex flex-col justify-between">
              <h2 className="text-primary capitalize font-medium">
                Passport details
              </h2>
              <div className="flex items-center gap-4 text-sm justify-between pb-12 border-b">
                <h3 className="text-N50">Passport number</h3>
                <p className="font-semibold text-N20">
                  {applicantData?.expatriate?.passport_number || "---"}
                </p>
              </div>
              <div className="flex text-sm mx-auto items-center gap-4 justify-between">
                <div className="flex items-center gap-2 text-N50">
                  <BsCalendarDate />
                  <p>Passport Issue Date</p>
                </div>
                <p className="font-semibold">
                  {(applicantData && applicantData?.passport_issued_date) || "---"}
                </p>
              </div>
              <div className="flex text-sm mx-auto items-center gap-4 justify-between">
                <div className="flex items-center gap-2 text-N50">
                  <BsCalendarDate className="text-primary" />
                  <p>Passport Expire Date</p>
                </div>
                <p className="font-semibold">
                  {(applicantData && applicantData?.passport_expiry_date) || "---"}
                </p>
              </div>
            </div>
            <div className="w-1/2 space-y-3">
              <h2 className="text-primary capitalize font-medium">
                VISA details
              </h2>
              <div className=" space-y-2">
                <div className="flex items-center gap-4 text-sm justify-between">
                  <h3 className="text-N50">VISA number</h3>
                  <p className="font-semibold text-N20">
                    {applicantData?.expatriate?.visa_number || "---"}
                  </p>
                </div>
                <div className="flex items-center gap-4 text-sm justify-between pb-6 border-b">
                  <h3 className="text-N50">VISA Type</h3>
                  <p className="font-semibold text-N20">
                    {applicantData?.expatriate?.visa_type?.name || "---"}
                    {/* {visaType?.name || applicantData?.visa_type?.name} */}
                  </p>
                </div>
              </div>
              <div className="flex text-sm mx-auto items-center gap-4 justify-between">
                <div className="flex items-center gap-2 text-N50">
                  <BsCalendarDate />
                  <p>VISA Issue Date</p>
                </div>
                <p className="font-semibold">
                  {(applicantData &&
                    applicantData?.expatriate?.visa_issued_date) ||
                    "---"}
                </p>
              </div>
              <div className="flex text-sm mx-auto items-center gap-4 justify-between">
                <div className="flex items-center gap-2 text-N50">
                  <BsCalendarDate className="text-primary" />
                  <p>VISA Expire Date</p>
                </div>
                <p className="font-semibold">
                  {(applicantData &&
                    applicantData?.expatriate?.visa_expiry_date) ||
                    "---"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-sm p-4 rounded-md space-y-8">
          <h2 className="text-primary capitalize font-medium">
            organization information
          </h2>
          <div className="flex items-center gap-4 divide-x-2">
            <div className="flex flex-col gap-3 items-center px-4">
              <div className="p-4 rounded-full bg-N99/60 w-20 h-20 flex items-center justify-center">
                <BsBuilding className="text-primary text-3xl" />
              </div>
              <div className=" space-y-1">
                <h3 className="font-semibold text-center text-N20">
                  {applicantData?.organization &&
                    applicantData?.organization?.name}
                </h3>
                <p className="text-N50 text-sm text-center">
                  {applicantData?.organization &&
                    applicantData?.organization?.sector?.name_json?.en}
                </p>
              </div>
            </div>
            <div className=" space-y-2 px-6 w-4/5">
              <InfoCard
                title="email"
                email={
                  applicantData?.organization &&
                  applicantData?.organization?.email
                }
              >
                <MdEmail />
              </InfoCard>
              <InfoCard
                title="phone number"
                email={
                  applicantData?.organization &&
                  applicantData?.organization?.phone_no
                }
              >
                <MdPhone />
              </InfoCard>
              <InfoCard
                title="Status"
                email={
                  applicantData?.organization &&
                  applicantData?.organization?.review_status
                }
              >
                <MdPhone />
              </InfoCard>
              <InfoCard
                title="tin number"
                email={
                  applicantData?.organization &&
                  applicantData?.organization?.tin_number
                }
              >
                <MdHouse />
              </InfoCard>
              <InfoCard
                title="business license"
                email={
                  applicantData?.organization &&
                  applicantData?.organization?.business_licence_number
                }
              >
                <MdFlag />
              </InfoCard>
              <InfoCard
                title="Town"
                email={
                  applicantData?.organization &&
                  applicantData?.organization?.subcity?.name_json?.en
                }
              >
                <MdEmail />
              </InfoCard>
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/3 space-y-6">
        <div className="bg-white shadow-sm p-4 rounded-md">
          <div className="flex flex-col gap-3 items-center -translate-y-1/3">
            <img
              src={
                applicantData &&
             imageUrl
              }
              className="rounded-full bg-N99/60 w-20 2xl:w-24 h-20 2xl:h-24 flex items-center justify-center object-cover"
            />
            <div className=" space-y-1">
              <h3 className="font-semibold text-N20">
                {applicantData?.expatriate?.first_name +
                  " " +
                  applicantData?.expatriate?.father_name}
              </h3>
              <p className="text-N50 text-sm text-center">
                {(applicantData &&
                  applicantData?.expatriate_work_permit_type?.name) ||
                  "New"}{" "}
                Application
              </p>
            </div>
          </div>
          <div className=" space-y-2 -mt-6 pb-8 border-b border-b-N95 mb-6">
            <InfoCard title="email" email={applicantData?.expatriate?.email}>
              <MdEmail />
            </InfoCard>
            <InfoCard
              title="phone number"
              email={applicantData?.expatriate?.phone_number}
            >
              <MdPhone />
            </InfoCard>
            <InfoCard
              title="gender"
              email={applicantData?.expatriate?.gender?.name}
            >
              <MdPhone />
            </InfoCard>
            <InfoCard
              title="date of birth"
              email={applicantData?.expatriate?.date_of_birth}
            >
              <MdHouse />
            </InfoCard>
            <InfoCard
              title="maritial status"
              email={applicantData?.expatriate?.marital?.name}
            >
              <MdFlag />
            </InfoCard>
            <InfoCard
              title="passport number"
              email={applicantData?.expatriate?.passport_number}
            >
              <MdEmail />
            </InfoCard>
          </div>

          {applicantData?.assigned_workpermit_reviewers &&
            applicantData?.assigned_workpermit_reviewers?.map((i) => (
              <div className="flex text-sm mx-auto items-center gap-4 justify-between mb-3">
                <div className="flex items-center gap-2 font-medium text-N0">
                  <BsUpcScan />
                  <p>
                    {i?.employee?.user_info?.allowed_roles[0]?.role_information
                      ?.value === roles.WP_DOC_CHECKER
                      ? "Document Checker"
                      : i?.employee?.user_info?.allowed_roles[0]
                          ?.role_information?.value === roles.WP_FINANCE
                      ? "Finance Checker"
                      : ""}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <p>{i?.employee?.user_info?.name}</p>
                  {/* <BsFillPersonCheckFill className="text-green50 text-lg" />
                  <FiAlertCircle className="text-N80 text-lg" /> */}
                </div>
              </div>
            ))}

          <div className="flex text-sm mx-auto items-center gap-4 justify-between">
            <div className="flex items-center gap-2 font-medium text-N0">
              <BsPersonXFill />
              <p>{"New Application"}</p>
            </div>
            <p>-</p>
          </div>
        </div>
        {applicantData?.assigned_workpermit_reviewers &&
          applicantData?.assigned_workpermit_reviewers?.length >= 1 && (
            <Can I={"view"} a={"manualAssign"}>
              {() => (
                <div className="bg-white p-4 space-y-2 -mt-6 pb-8 border-b border-b-N95/20 mb-6">
                  <p>Manual Assign Employee</p>
                  <FormProvider
                    onSubmithandler={handleSubmit(handleManualAssign)}
                  >
                    <CSelectField
                      name="user_id"
                      register={register}
                      errors={errors}
                      options={employeeData?.filter(i => !["Test","ministry"].includes(i?.user_info?.name))?.map((dis) => {
                        const isAssigned =
                          applicantData?.assigned_workpermit_reviewers &&
                          applicantData?.assigned_workpermit_reviewers?.some(
                            (doc) =>
                              doc?.employee?.user_info.id === dis?.user_info?.id
                          );
                        console.log("_per__", isAssigned);
                        return {
                          ...dis,
                          name: dis?.user_info?.name,
                          value: dis?.user_info?.id,
                          disabled: isAssigned,
                        };
                      })}
                      label=""
                      placeholder="Select District"
                    />
                    <CButton
                      type={"submit"}
                      loading={assignLoading}
                      classes={"bg-primary"}
                      btnLabel={"Assign"}
                    />
                  </FormProvider>
                </div>
              )}
            </Can>
          )}
        <div className="bg-white shadow-sm p-4 rounded-md space-y-4">
          <StatusCard
            user={applicantData && applicantData?.assigned_workpermit_reviewers}
            status={
              applicantData?.review_status === true
                ? "verified"
                : applicantData?.review_status === false
                ? "rejected"
                : "pending"
            }
          />
        </div>
        <div className="bg-white shadow-sm p-4 rounded-md space-y-4">
          <h2 className="text-primary capitalize font-medium text-sm">
            work permit documents
          </h2>
          {applicantData &&
            applicantData?.expatriate_work_permit_documents?.map((item) => (
              <WorkPermitCard
                title={item?.title}
                path={item?.files}
                note={item?.review_note}
                status={
                  item?.review_status
                    ? "Verified"
                    : item?.review_status === false
                    ? "Rejected"
                    : "Pending"
                }
              />
            ))}

          {/* <WorkPermitCard title="work experience" status="Verified" />
          <WorkPermitCard title="Passport" status="Verified" /> */}
        </div>
      </div>
    </div>
  );
};

export default ApplicantProfile;
