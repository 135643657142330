import React, { useEffect, useRef } from "react";
import { CInputField, FormProvider } from "../../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import {
  MdArrowBack,
  MdEdit,
  MdEmail,
  MdFlag,
  MdHouse,
  MdPhone,
  MdWarning,
} from "react-icons/md";
import { useLocalStore } from "../../../store";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { RenewalRequestSchema } from "../../../utils/SchemaValidation";
import {
  BASE_REGION,
  BASE_SUBCITY,
  BASE_WOREDA,
  BASE_ZONE,
  GET_EXPATRIATE_BYID,
  GET_ORG_EXPATRIATE_BY_ID,
} from "../../../graph-query/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { BsCalendarDate, BsBuilding } from "react-icons/bs";
import InfoCard from "../../../components/cards/InfoCard";
import FileInput from "../../../components/Forms/Fields/FileInput";
import { toast } from "react-toastify";
import {
  INSERT_EXPATRIATE_DOC,
  INSERT_EXPATRIATE_REQUEST,
  INSERT_RENEWAL_PERMIT_REQUEST,
  UPLOAD_FILE,
} from "../../../graph-query/mutation";
import { getBase64 } from "../../../utils/utilityFunctions";
import { trackPromise } from "react-promise-tracker";
import { WORK_PERMIT_TYPES } from "../../../utils/constants";
import CButton from "../../../components/Forms/CButton";

const RenewalDetail = () => {
  const [currentTab, setCurrentTab] = useState("personal");
  const [files, setFiles] = useState([]);
  const [exptData, setExptData] = useState();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RenewalRequestSchema),
  });

  const bank_watch = watch("finance_slip");

  const profileRef = useRef();
  const [disabled, setDisabled] = useState(true);

  const { id } = useParams();
  const router = useNavigate();

  const {
    visaTypes,
    regions,
    zones,
    subcities,
    woredas,
    permitPrice,
    setRegions,
    setSubCities,
    setWoredas,
    setZones,
  } = useLocalStore();

  const selectedRegion = watch("region_id");
  const selectedSubCity = watch("subcity_id");

  const [create_permit, { loading, error }] = useMutation(
    INSERT_RENEWAL_PERMIT_REQUEST,
    {
      onCompleted() {
        router("/organization");
      },
      onError: (er) => {
        toast.error(er?.message);
      },
    }
  );
  const [getExpatData, {}] = useLazyQuery(GET_ORG_EXPATRIATE_BY_ID, {
    variables: {
      id: id,
    },
    onCompleted: (da) => {
      console.log(da?.expatriates);
      setExptData(da?.expatriates[0]);
    },
  });

  const [getRegions, {}] = useLazyQuery(BASE_REGION, {
    onCompleted: (data) => {
      console.log("regions data:", data?.base_regions);
      setRegions(data?.base_regions);
    },
  });

  const [getSubCity, {}] = useLazyQuery(BASE_SUBCITY, {
    variables: {
      region: selectedRegion,
    },
    onCompleted: (data) => {
      console.log("subcities data:", data?.base_subcities);
      setSubCities(data?.base_subcities);
    },
  });

  const [getWoreda, {}] = useLazyQuery(BASE_WOREDA, {
    variables: {
      subcity: selectedSubCity,
    },
    onCompleted: (data) => {
      console.log("woreda data:", data?.base_woredas);
      setWoredas(data?.base_woredas);
    },
  });

  const [getZone, {}] = useLazyQuery(BASE_ZONE, {
    variables: { region: selectedRegion },
    onCompleted: (data) => {
      console.log("zones data:", data?.base_zones);
      setZones(data?.base_zones);
    },
  });

  useEffect(() => {
    if (regions?.length === 0) {
      getRegions();
    }
  }, [regions, setRegions]);

  useEffect(() => {
    // if (subcities?.length === 0) {
    getSubCity();
    // }
  }, [subcities, setSubCities]);

  useEffect(() => {
    getWoreda();
  }, [woredas, setWoredas]);

  useEffect(() => {
    getZone();
  }, [zones, setZones]);

  useEffect(() => {
    trackPromise(getExpatData());
  }, []);

  // ================================================
  const handleVisaFile = (e) => {
    console.log("visa file", e);
    // setFiles((previousFile) => ({ ...previousFile, visa: e }));
    if (e) {
      getBase64(e).then((result) => {
        let fileType = (e?.type).split("/").length
          ? (e?.type).split("/")[1]
          : "";
        setFiles((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            document_type_id: "ad702374-171c-4d4b-b3f6-161170e3db0b",
            catagory: "51ff2b29-4d56-449c-a69d-08348d7299b4",
            title: "Visa",
            doc_type: fileType,
          },
        ]);
      });
    }
  };

  const handlePassport = (e) => {
    console.log("passport file", e);
    if (e) {
      getBase64(e).then((result) => {
        let fileType = (e?.type).split("/").length
          ? (e?.type).split("/")[1]
          : "";

        setFiles((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            document_type_id: "56de0cbc-7986-4411-8a35-aba426181a07",
            catagory: "51ff2b29-4d56-449c-a69d-08348d7299b4",
            title: "Passport",
            doc_type: fileType,
          },
        ]);
      });
    }
  };

  const handleBankSlip = (e) => {
    console.log("bank slip file", e);
    if (e) {
      getBase64(e).then((result) => {
        let fileType = e?.type.split("/").length ? e?.type.split("/")[1] : "";
        setFiles((prevFiles) => {
          // Check if the title already exists in the files array
          const existingFileIndex = prevFiles.findIndex(
            (file) => file.title === "Bank Slip"
          );
          if (existingFileIndex !== -1) {
            // Replace the existing file with the new file
            const updatedFiles = [...prevFiles];
            updatedFiles[existingFileIndex] = {
              file: result,
              document_type_id: "3aff76f9-f65a-4ee2-8a4e-9a551802ad26",
              catagory: "166d1c11-6ce1-41ce-87eb-b83bf8ade238",
              title: "Bank Slip",
              doc_type: fileType,
            };
            return updatedFiles;
          } else {
            // Add the new file if the title doesn't exist
            return [
              ...prevFiles,
              {
                file: result,
                document_type_id: "3aff76f9-f65a-4ee2-8a4e-9a551802ad26",
                catagory: "166d1c11-6ce1-41ce-87eb-b83bf8ade238",
                title: "Bank Slip",
                doc_type: fileType,
              },
            ];
          }
        });
      });
    }
  };

  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);
  const [permitDocument, { loading: permitLoading }] = useMutation(
    INSERT_EXPATRIATE_DOC
  );

  const [renewalRequest, {}] = useMutation(INSERT_RENEWAL_PERMIT_REQUEST, {
    onCompleted: (d) => {
      toast.success("Renewal Application Requested Successfully!");
    },
  });

  const checkTitleContainsKeywords = (files, title) => {
    const foundFile = files.find((file) => {
      const titleLowercase = file.title.toLowerCase();
      return titleLowercase?.includes(title);
    });

    return foundFile !== undefined;
  };

  const uploadDocuments = (result, fileType, cb) => {
    // console.log(result);
    // extract the file type to have the folowing pdf,png ...
    // let fileType = (uploadedFile?.type).split("/").length
    //   ? (uploadedFile?.type).split("/")[1]
    //   : "";
    // upload the file to the server
    trackPromise(
      fileUpload({
        variables: {
          extension: fileType,
          file: result,
        },
        onCompleted(data) {
          // console.log(data);
          cb && cb({ path: data.uploadFile.info.Key });
          // toast.success("Document Uploaded Successfully!");
        },
        onError(error) {
          // toast.error("Document upload failed!");
        },
      })
    );
  };

  const handleApplication = (e) => {
    let renewalFiles = [];
    console.log("renewal: ", e);
    const keywordsToCheck = ["passport", "visa", "bank"];
    const passCheck = checkTitleContainsKeywords(files, keywordsToCheck[0]);
    const visaCheck = checkTitleContainsKeywords(files, keywordsToCheck[1]);
    const bankCheck = checkTitleContainsKeywords(files, keywordsToCheck[2]);

    if (!bank_watch) {
      toast.error("Please insert Bank Transaction");
    }
    if (!passCheck) {
      toast.error("Please Upload Passport");
    }
    if (!visaCheck) {
      toast.error("Please Upload Visa");
    }
    if (!bankCheck) {
      toast.error("Please Upload Bank Slip");
    } else {
      if (bank_watch && passCheck && visaCheck) {
        console.log(files);
        const uploadPromises = files?.map((i) => {
          return new Promise((resolve, reject) => {
            uploadDocuments(
              i?.file,
              i.doc_type,
              (filePath) => {
                renewalFiles.push({
                  ...filePath,
                  document_type_id: i.document_type_id,
                  catagory: i?.catagory, 
                  title: i.title,
                });
                resolve(filePath);
              },
              reject
            );
          });
        });
        console.log(exptData);
        Promise.all(uploadPromises).then(() => {
          create_permit({
            variables: {
              ...exptData.expatriate_work_permits[0],
              expatriate_id: id,
              allowance: exptData?.expatriate_work_permits[0]?.allowance,
              permit_type: WORK_PERMIT_TYPES.RENEWAL,
              reference_number: bank_watch,
              employment_end_date:
                exptData?.expatriate_work_permits[0]?.end_date,
              employment_start_date:
                exptData?.expatriate_work_permits[0]?.start_date,
              salary: exptData?.expatriate_work_permits[0]?.salary,
              organization_id:
                exptData?.expatriate_work_permits[0]?.organization?.id,
              occupation_category_id:
                exptData?.expatriate_work_permits[0]?.occupation_category?.id,
              issued_date: exptData?.expatriate_work_permits[0]?.issued_date,
              expiry_date: exptData?.expatriate_work_permits[0]?.expiry_date,
              emr_number: exptData?.expatriate_work_permits[0]?.emr_number,
            },
            onCompleted: async (data) => {
              console.log("data__", data);
              await renewalFiles.map(async (i) => {
                console.log(i);
                await permitDocument({
                  variables: {
                    documentId: i?.document_type_id,
                    expatriate_work_permit_id:
                      data?.insert_expatriate_work_permits?.returning[0]?.id,
                    files: i?.path,
                    properties: i,
                    title: i?.title,
                  },
                });
              });
            },
          });
        });
      }
    }
  };

  return (
    <>
      <div className="flex gap-6">
        <div className="w-2/3 space-y-6">
          <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
            <button
              onClick={() => router(-1)}
              className="bg-gray px-3 py-2 border-2 rounded-md flex gap-2 items-center"
            >
              <MdArrowBack /> Back
            </button>
            <h2 className="text-primary capitalize font-semibold">
              Work Permit Details
            </h2>
            <div className="flex gap-4 items-start">
              <div className="flex flex-col gap-4 w-2/5">
                <div className="rounded bg-N99/30 p-3 space-y-1">
                  <p className="text-sm text-N70 capitalize">EMR number</p>
                  <h3 className="2xl:text-lg text-primary font-semibold tracking-wider">
                    {exptData?.expatriate_work_permits[0]?.emr_number || "---"}
                  </h3>
                </div>
                <div className="rounded bg-N99/30 p-3 space-y-1">
                  <p className="text-sm text-N70 capitalize">tracking Number</p>
                  <h3 className="text-lg text-primary font-semibold tracking-wider">
                    {exptData?.expatriate_work_permits[0]?.tracking_number ||
                      "---"}
                  </h3>
                </div>
              </div>
              <div className="w-3/5 rounded-md text-sm bg-N99/30 p-3 space-y-4">
                <h2 className="text-primary capitalize font-medium">
                  Passport details
                </h2>
                <div className="flex items-center gap-4 text-sm justify-between pb-4 border-b">
                  <h3 className="text-N50">Passport number</h3>
                  <p className="font-semibold text-N20">
                    {exptData?.passport_number || "---"}
                  </p>
                </div>
                <div className="flex items-center gap-4 text-sm justify-between pb-4 border-b">
                  <h3 className="text-N50">Visa number</h3>
                  <p className="font-semibold text-N20">
                    {exptData?.visa_number || "---"}
                  </p>
                </div>
                <div className="flex text-sm mx-auto items-start gap-4 justify-between">
                  <div className="flex items-start gap-2 text-N50">
                    <BsCalendarDate />
                    <p>Passport Issue Date</p>
                  </div>
                  <p className="font-semibold">
                    {(exptData?.expatriate_work_permits &&
                      exptData?.expatriate_work_permits[0]?.issued_date) ||
                      "---"}
                  </p>
                </div>
                <div className="flex text-sm mx-auto items-start gap-4 justify-between">
                  <div className="flex items-start gap-2 text-N50">
                    <BsCalendarDate className="text-primary" />
                    <p>Passport Expire Date</p>
                  </div>
                  <p className="font-semibold">
                    {(exptData?.expatriate_work_permits &&
                      exptData?.expatriate_work_permits[0]?.expiry_date) ||
                      "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-sm p-4 rounded-md space-y-8">
            <h2 className="text-primary capitalize font-medium">
              organization information
            </h2>
            <div className="flex items-center gap-4 divide-x-2">
              <div className="flex flex-col gap-3 items-center px-4">
                <div className="p-4 rounded-full bg-N99/60 w-20 h-20 flex items-center justify-center">
                  <BsBuilding className="text-primary text-3xl" />
                </div>
                <div className=" space-y-1">
                  <h3 className="font-semibold text-center text-N20">
                    {exptData?.expatriate_work_permits &&
                      exptData?.expatriate_work_permits[0]?.organization?.name}
                  </h3>
                  <p className="text-N50 text-sm text-center">
                    {exptData?.expatriate_work_permits &&
                      exptData?.expatriate_work_permits[0]?.organization?.sector
                        ?.name_json?.en}
                  </p>
                </div>
              </div>
              <div className=" space-y-2 px-6 w-4/5">
                <InfoCard
                  title="email"
                  email={
                    exptData?.expatriate_work_permits &&
                    exptData?.expatriate_work_permits[0]?.organization?.email
                  }
                >
                  <MdEmail />
                </InfoCard>
                <InfoCard
                  title="phone number"
                  email={
                    exptData?.expatriate_work_permits &&
                    exptData?.expatriate_work_permits[0]?.organization?.phone_no
                  }
                >
                  <MdPhone />
                </InfoCard>
                <InfoCard
                  title="Status"
                  email={
                    exptData?.expatriate_work_permits &&
                    exptData?.expatriate_work_permits[0]?.organization
                      ?.review_status
                  }
                >
                  <MdPhone />
                </InfoCard>
                <InfoCard
                  title="tin number"
                  email={
                    exptData?.expatriate_work_permits &&
                    exptData?.expatriate_work_permits[0]?.organization
                      ?.tin_number
                  }
                >
                  <MdHouse />
                </InfoCard>
                <InfoCard
                  title="business licence"
                  email={
                    exptData?.expatriate_work_permits &&
                    exptData?.expatriate_work_permits[0]?.organization
                      ?.business_licence_number
                  }
                >
                  <MdFlag />
                </InfoCard>
                <InfoCard
                  title="Town"
                  email={
                    exptData?.expatriate_work_permits &&
                    exptData?.expatriate_work_permits[0]?.organization?.subcity
                      ?.name_json?.en
                  }
                >
                  <MdEmail />
                </InfoCard>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/3 space-y-6">
          <div className="bg-white shadow-sm p-4 rounded-md">
            <div className="flex flex-col gap-3 items-center -translate-y-1/3">
              <div class="relative inline-flex items-center justify-center w-20 2xl:w-24 h-20 2xl:h-24 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span class="font-medium text-gray-600 dark:text-gray-300 text-3xl">
                  {exptData?.first_name?.substring(0, 1) +
                    exptData?.father_name?.substring(0, 1)}
                </span>
              </div>

              <div className=" space-y-1">
                <h3 className="font-semibold text-N20">
                  {exptData?.first_name + " " + exptData?.father_name}
                </h3>
                <p className="text-N50 text-sm text-center">
                  {(exptData?.expatriate_work_permits &&
                    exptData?.expatriate_work_permits[0]
                      ?.expatriate_work_permit_type?.name) ||
                    "New"}{" "}
                  Application
                </p>
              </div>
            </div>
            <div className=" space-y-2 -mt-6 pb-8 border-b border-b-N95 mb-6">
              <InfoCard title="email" email={exptData?.email}>
                <MdEmail />
              </InfoCard>
              <InfoCard title="phone number" email={exptData?.phone_number}>
                <MdPhone />
              </InfoCard>
              <InfoCard title="gender" email={exptData?.genderByGender?.name}>
                <MdPhone />
              </InfoCard>
              <InfoCard title="date of birth" email={exptData?.date_of_birth}>
                <MdHouse />
              </InfoCard>
              <InfoCard
                title="maritial status"
                email={exptData?.maritalStatusByMaritalStatus?.name}
              >
                <MdFlag />
              </InfoCard>
              <InfoCard
                title="passport number"
                email={exptData?.passport_number}
              >
                <MdEmail />
              </InfoCard>
            </div>
          </div>
        </div>
      </div>
      <FormProvider onSubmithandler={handleSubmit(handleApplication)}>
        <div className="bg-white mt-10">
          <h4 className="font-bold text-2xl p-4">
            Request Renewal Application
          </h4>
          <div className="px-4 py-5">
            <CInputField
              register={register}
              errors={errors}
              label={"Finance Slip Reference ID"}
              placeholder={"Enter slip transaction ID"}
              name={"finance_slip"}
            />
          </div>
          <div className="p-5">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3>Upload Passport</h3>
                <FileInput
                  onChange={(e) => handlePassport(e)}
                  enablePreview={true}
                  type={"file"}
                />
              </div>
              <div>
                <h3>Upload Visa</h3>
                <FileInput
                  onChange={(e) => handleVisaFile(e)}
                  enablePreview={true}
                  type={"file"}
                />
              </div>
            </div>
            <div className="flex w-3/5 mt-8">
              <div className="border-2 rounded-md shadow-xl p-5">
                <div className="flex gap-4 text-primary font-semibold text-lg">
                  <MdWarning /> <span className="">Notice</span>
                </div>
                <p className="text-sm">
                  Before applying Renewal application the application provider
                  shall pay{" "}
                  <span className="text-lg font-semibold">
                    {
                      permitPrice
                        ?.filter((i) => i?.work_permit_type?.name === "Renewal")
                        .map((i) => i?.price)[0]
                    }{" "}
                    ETB
                  </span>
                </p>
                <div className="mt-4 flex gap-4 font-semibold text-lg text-primary">
                  <MdWarning /> All checklists must be filed out.
                </div>
              </div>
            </div>
            <div className="mt-4">
              <h3>Upload Bank Slip</h3>
              <FileInput
                onChange={(e) => handleBankSlip(e)}
                enablePreview={true}
                type={"image"}
              />
            </div>

            <CButton
              handleClick={handleApplication}
              btnLabel={"Request Renewal Application"}
              type="submit"
              loading={loading}
              classes={"bg-primary"}
              className="mt-5 border-2 rounded-md bg-primary text-white text-xs px-4 py-2 hover:opacity-80 hover:shadow-md"
            />
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default RenewalDetail;
