import React, { useEffect, useState } from "react";
import DocumentCard from "../../components/cards/DocumentCard";
import { BsFillPersonFill } from "react-icons/bs";
import { IoReturnUpBack } from "react-icons/io5";
import CheckDocumentModal from "../../components/utilities/CheckDocumentModal";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_APPLICANT_REQUEST_BYID,
  GET_EXPATRIATE_BYID,
  GET_WORK_PERMIT_BYID,
} from "../../graph-query/queries";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CTextArea, FormProvider } from "../../components/Forms/Fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToaster } from "../../hooks/useToaster";
import {
  REJECT_WORK_PERMIT_DOCUMENT,
  UPDATE_WORK_PERMIT_DOCUMENT,
  VERIFY_EXPATRIATE_DOCUMENT,
  VERIFY_FINISH_DOCUMENT_PERMIT,
  VERIFY_FINISH_PERMIT,
} from "../../graph-query/mutation";
import { useLocalStore } from "../../store";
import { BANK_SLIP_ID, PROFILE_IMAGE_ID } from "../../utils/constants";
import { MdNoteAlt, MdPictureAsPdf, MdVerified, MdVisibility } from "react-icons/md";
import { trackPromise } from "react-promise-tracker";
import { Can } from "../../permission/Can";
import { roles } from "../../utils/roles";
import {
  GetLatestProfilePic,
  arragePermitDocumentCatagory,
  checkArrayObjects,
  checkLatestDocumentCategoriesVerification,
  filterAndGroupByDocumentTypeAndCategory,
  isDocumentVerified,
  processDocuments,
} from "../../utils/utilityFunctions";
import RejectModal from "../../components/modals/RejectModal";
import DocumentViewerDrawer from "../../components/modals/DocumentViewerDrawer";
import Tooltip from "../../components/Tooltip";
import Moment from "react-moment";
import { getImageUrl } from "../../components/common/imageUtils";

const rejectSchema = yup
  .object({
    rejectSlip: yup.boolean().required(),
    message: yup.string().min(5).required(),
  })
  .required();

const DocumentView = () => {
  const [show, setShow] = useState(false);
  const [tabDisplay, setTabDisplay] = useState("APPLICATION");
  const [enableReject, setEnableReject] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [disableFinish, setDisableFinish] = useState(false);
  const [rejectModalDoc, setRejectModalDoc] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showData, setShowData] = useState();
  const [recievedData, setRecievedData] = useState();

  const { id } = useParams();

  const router = useNavigate();
  const toast = useToaster();

  const { currentRole, user, documentTypeCategories } = useLocalStore();

  const FilteredDocument = filterAndGroupByDocumentTypeAndCategory(
    recievedData?.expatriate_work_permit_documents,
    documentTypeCategories
  );

  useEffect(() => {
    if (recievedData) {
      const allResolved = processDocuments(
        recievedData?.expatriate_work_permit_documents
      );
      setDisableFinish(allResolved);
    }
  }, [recievedData, setRecievedData]);

  // console.log("__document",FilteredDocument)

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(rejectSchema),
  });

  const handleModal = (w) => {
    setShowData(w);
    setShow((prev) => !prev);
  };

  const [getData, { loading }] = useLazyQuery(GET_WORK_PERMIT_BYID, {
    variables: {
      id: id,
    },
    onCompleted: (data) => {
      setRecievedData(data?.expatriate_work_permits[0]);
    },
  });

  useEffect(() => {
    trackPromise(getData());
  }, []);

  const extractUniqueTitles = (documents) => {
    const uniqueTitles = new Set();
    documents.forEach((doc) => {
      uniqueTitles.add(doc.title);
    });
    return Array.from(uniqueTitles);
  };

  const handleRejection = async (d) => {
    // await reject_work_permit({
    //   variables: { id: showData?.id, verified: false, remark: d.message },
    // });
  };

  const handleFinish = (id) => {
    //validate if all document is verified
    const titleDoc = extractUniqueTitles(
      recievedData?.expatriate_work_permit_documents
    );

    if (titleDoc) {
      console.log(titleDoc);
      let res = [];
      titleDoc.map((item) => {
        const _value = isDocumentVerified(
          item,
          recievedData?.expatriate_work_permit_documents
        );
        res.push(_value);
      });
      console.log(res);

      const checkStatus = res.some((value) => value === true);

      if (checkStatus) {
        // console.log(application);
        console.log(checkStatus);
        finishReview({
          variables: {
            permitID: id,
            checkerStatus: true,
          },
          onCompleted: (d) => {
            toast.SuccessToast("Document Review Finish Successfully");
            getData({ fetchPolicy: "network-only" });
          },
          onError: (er) => {
            toast.ErrorToast("Unable to finish Document Review");
          },
        });
      } else {
        toast.WarnToast("Please Make sure all documents are Verified");
      }
    }
  };

  const [update_work_permit, { fin_data, loading: signInLoading }] =
    useMutation(VERIFY_EXPATRIATE_DOCUMENT, {
      onError(err) {
        const error = `${err}`.split(":").reverse()[0];
        console.log(error.trim());
      },
      onCompleted(d) {
        console.log(d);
        // getData();
      },
    });

  const [finishReview, {}] = useMutation(VERIFY_FINISH_PERMIT);

  const verifiyDocument = async () => {
    await update_work_permit({
      variables: {
        id: selectedDocument?.id,
        review_status: true,
        verified_by: user?.id,
        verified_on: new Date(),
      },
    }).then(() => {
      toast.SuccessToast("Document Verified!");
      // handleModal(showData.id);
      setOpenDrawer(false);
      getData({ fetchPolicy: "network-only" });
    });
  };

  /**
   * @description handle doc rejection
   * @param {*} q
   */
  const handleReject = async (q) => {
    await update_work_permit({
      variables: {
        id: selectedDocument?.id,
        review_note: q.review_note_doc,
        review_status: false,
      },
    }).then(() => {
      toast.WarnToast("Document Rejected!");
      setRejectModalDoc(false);
      setOpenDrawer(false);
      // handleModal(selectedDocument.id);
      getData({ fetchPolicy: "network-only" });
    });
  };
  const handleRejectPermit = (e) => {
    finishReview({
      variables: {
        permitID: recievedData?.id,
        checkerStatus: false,
        status: false,
        note: e?.review_note_doc,
      },
      onCompleted: () => {
        toast.SuccessToast("Work Permit Rejected!");
        setRejectModal(false);
      },
    });
  };

  const handleSelectDocument = (d) => {
    console.log("selected document: ", d);
    setOpenDrawer(!openDrawer);
    setSelectedDocument(d);
  };

  const handleRejectModalDoc = () => {
    setRejectModalDoc((prev) => !prev);
  };
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (GetLatestProfilePic(
        recievedData
      )) {
        const url = await getImageUrl(GetLatestProfilePic(
          recievedData
        ));
        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [GetLatestProfilePic(
    recievedData
  )]);


  const [imageUrls, setImageUrls] = useState({});

  // Fetch and store the URLs in state
  useEffect(() => {
    const loadImageUrls = async () => {
      const urls = {};

      for (const key of Object.keys(FilteredDocument)) {
        const category = FilteredDocument[key];
        if (category.category === tabDisplay) {
          for (const item of category.documents) {
            const url = await getImageUrl(item.files);
            urls[item.files] = url;
          }
        }
      }

      setImageUrls(urls);
    };

    loadImageUrls();
  }, [FilteredDocument, tabDisplay]);


  return (
    <>
      <div>
        <div className="w-full bg-white p-4 rounded-md space-y-4">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex flex-wrap items-center gap-4">
              <div className="h-full text-white rounded-md text-[5rem] bg-prime40/25 flex flex-wrap items-center justify-center p-2">
                <img
                  src={imageUrl}
                  className="w-36 h-36 object-cover"
                  alt={recievedData?.expatriate?.first_name}
                />
              </div>
              <div className="flex flex-col capitalize">
                <h2 className=" font-semibold flex flex-wrap mb-1">
                  {`${recievedData?.expatriate?.first_name} ${
                    recievedData?.expatriate?.father_name
                  } ${
                    recievedData?.expatriate?.grand_father_name !== null
                      ? recievedData?.expatriate?.grand_father_name
                      : ""
                  }`}
                </h2>
                <p className=" text-sm font-medium mb-2">
                  {recievedData?.expatriate?.nationality?.name}
                </p>
                <span className="p-1 self-start rounded-md text-xs font-medium text-primary bg-prime40/40 border-primary border">
                  {recievedData?.expatriate_work_permit_type?.name} application
                </span>
                <small className="mt-2 font-normal">
                  Applied on:{" "}
                  <Moment format="YYYY-MM-DD">
                    {recievedData?.created_at}
                  </Moment>
                </small>
              </div>
            </div>
            <div className="md:w-1/4 2xl:w-1/5 w-full space-y-2">
              <Can I={"action"} a={"documentCheck"}>
                {() => (
                  <button
                    disabled={
                     recievedData?.document_checker_status || !disableFinish
                    }
                    // ||  !disableFinish || 
                    onClick={() => handleFinish(recievedData?.id)}
                    className={`disabled:opacity-40 disabled:pointer-events-none bg-primary w-full text-white py-2 rounded`}
                  >
                    Finish
                  </button>
                )}
              </Can>
              <Can I={"action"} a={"rejectPermit"}>
                {() => (
                  <button
                    onClick={() => setRejectModal(true)}
                    className={`${
                      recievedData?.review_status === false
                        ? "opacity-40 pointer-events-none"
                        : ""
                    } bg-red w-full text-white py-2 rounded`}
                  >
                    Reject Permit
                  </button>
                )}
              </Can>
              <button
                onClick={() => router(-1)}
                className="border-primary w-full border flex items-center justify-center gap-2 text-primary py-2 rounded"
              >
                <IoReturnUpBack />
                <p>go back</p>
              </button>
            </div>

            {currentRole !== roles.WP_EMR_GENERATOR && (
              <div className="md:w-1/3 w-full space-y-4">
                <input
                  onChange={() => setEnableReject(!enableReject)}
                  type="checkbox"
                  id="reject"
                  label="rejectSlip"
                />
                <label htmlFor="reject"> Reject Slip</label>
                <FormProvider onSubmithandler={handleSubmit(handleRejection)}>
                  <div className="bg-white p-3">
                    <CTextArea
                      label="Message"
                      name="message"
                      disabled={!enableReject}
                      placeholder="Here the reason for the rejection"
                      register={register}
                      errors={errors}
                      validation={{ required: true }}
                    ></CTextArea>
                    {/* <textarea
                    type="text"
                    className="h-28 w-full p-2 border-none rounded-sm focus:ring-0 focus:outline-none"
                    placeholder="Here the reason for the rejection"
                  ></textarea> */}
                    <div className="flex justify-end">
                      <button
                        type="button"
                        disabled={!enableReject}
                        className="disabled:pointer-events-none disabled:cursor-not-allowed bg-N95/70 py-2 w-1/2 font-semibold text-N50"
                      >
                        reject
                      </button>
                    </div>
                  </div>
                </FormProvider>
              </div>
            )}
          </div>

          {/* alert message if there is any */}
          {recievedData?.review_status === false && (
            <div
              className="flex items-center p-4 mb-4 text-sm text-red40 border border-red30 rounded-lg "
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 mr-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium mr-2">Rejected!</span>{" "}
                {recievedData?.reviewed_note}
              </div>
            </div>
          )}

          <div>
            <div className="flex flex-col justify-start relative overflow-hidden py-4">
              <div className="max-w-7xl md:mx-[2rem] mx-2">
                <div className="relative group">
                  <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-100 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
                  <div className="relative px-7 py-6 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                    <MdNoteAlt className="sm:hidden visible" size={32}/>
                    <div className="space-y-2">
                      <p className="text-slate-800">
                        Permit Verification process (Document Checker Only)
                      </p>
                      <span
                        className="bg-indigo-400 px-4 py-1 rounded-2xl w-fit block text-white transition duration-200"
                      >
                        {recievedData?.document_checker_status ? 'Documents Verified' : 'Documents Not Verified'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-200 dark:border-gray-700">
            <ul className="flex bg-gray-300 p-3 flex-nowrap overflow-x-auto -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
              {FilteredDocument &&
                Object?.keys(FilteredDocument)?.map((keys, index) => (
                  <li key={index} className="mr-2">
                    <button
                      onClick={() =>
                        setTabDisplay(FilteredDocument[keys]?.category)
                      }
                      className={`${
                        tabDisplay === FilteredDocument[keys].category
                          ? "bg-primary text-white border-primary hover:opacity-70 hover:text-white"
                          : ""
                      } inline-flex border-2 items-center justify-center px-4 py-1 border-b-2 border-transparent rounded-3xl hover:text-gray-600 hover:border-gray-300 group`}
                    >
                      {FilteredDocument[keys]?.category}
                    </button>
                  </li>
                ))}
            </ul>
          </div>

          {FilteredDocument &&
            Object?.keys(FilteredDocument)?.map((keys, index) => {
            
              if (FilteredDocument[keys]?.category === tabDisplay) {
                return FilteredDocument[keys]?.documents?.map((item) => (
                  <div className="bg-white border-2 border-gray-200 rounded-md overflow-hidden">
                    <div className="relative p-4 flex justify-between items-center">
                      <div className="flex gap-4">
                        <a
                          rel="noopener noreferrer"
                          onClick={() => handleSelectDocument(item)}
                          className="w-36 h-36 rounded-md flex justify-center items-center hover:opacity-60 cursor-pointer hover:scale-105 relative"
                        >
                          {item?.files.split(".").pop() !== "pdf" ? (
                            <img
                              className="w-full h-full object-cover"
                              src={imageUrls[item.files]}
                              alt=""
                            />
                          ) : (
                            <div className="w-full h-full flex justify-center items-center">
                              <embed
                                width="100%"
                                height="100%"
                                pa
                                src={imageUrls[item.files]}
                              />
                            </div>
                          )}
                          <div className="absolute inset-0 bg-black/30 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                            <MdVisibility size={24} className="text-white" />
                          </div>
                        </a>
                        <div className="flex flex-col gap-3">
                          <h3 className="font-semibold capitalize text-lg">
                            {item?.title}
                          </h3>
                          <p>Category</p>
                          <small>
                            Document Type: {item?.files.split(".").pop()}
                          </small>
                          <span
                            className={`${
                              item?.review_status === true
                                ? "bg-green50"
                                : item?.review_status === false
                                ? "bg-red50"
                                : "bg-yellow50"
                            } text-center rounded w-fit text-white px-3 py-0.5`}
                          >
                            {item?.review_status === true
                              ? "Verified"
                              : item?.review_status === false
                              ? "Rejected"
                              : "Pending"}
                          </span>
                        </div>
                      </div>
                      {item?.reviewed_by_info && (
                        <div className="absolute bottom-2 right-2">
                          <Tooltip
                            tooltipsText={`${
                              item.review_status ? "Verified" : "Rejected"
                            } this document`}
                          >
                            <span className="bg-blue-100 flex gap-2 items-center rounded px-4 py-0.5">
                              <MdVerified />
                              {item?.reviewed_by_info?.name}
                            </span>
                          </Tooltip>
                        </div>
                      )}
                      {/* Hide Bank Slip Check Buttons for document checkers */}
                      {item?.document_type?.id !== BANK_SLIP_ID && (
                        <Can I="action" a="documentCheck">
                          {() => (
                            <div className="flex flex-col gap-3">
                              <button
                                type="button"
                                onClick={() => handleSelectDocument(item)}
                                className="px-5 py-1 rounded-md border-2 border-primary text-primary text-sm"
                              >
                                Check
                              </button>
                            </div>
                          )}
                        </Can>
                      )}
                    </div>
                  </div>
                ));
              }
            })}
        </div>

        {rejectModal && (
          <RejectModal
            setShowDocumentModal={setRejectModal}
            title={"Reject Work permit"}
            handleRejectDocument={handleRejectPermit}
          />
        )}
      </div>
      {/* Drawer to view PDF */}
      {openDrawer && (
        <DocumentViewerDrawer
          selectedDocument={selectedDocument}
          handleVerify={verifiyDocument}
          handleRejection={handleRejectModalDoc}
          handleCloseDrawer={() => setOpenDrawer(false)}
        />
      )}

      {rejectModalDoc && (
        <RejectModal
          setShowDocumentModal={setRejectModalDoc}
          title={"Reject Work permit Document"}
          handleRejectDocument={handleReject}
        />
      )}
    </>
  );
};

export default DocumentView;
