import HelpingGuides from "../components/landing/HelpingGuides";
import HeroSection from "../components/landing/HeroSection";
import Navbar from "../components/landing/Navbar";
import Footer from "../components/common/Footer";
const LandingPage = () => {
  return (
    <>
      <div className="space-y-16">
        <Navbar />
        <HeroSection />
        <HelpingGuides />
        <Footer />
        {/* <div className="fixed top-1/2 p-7 text-white text-lg -left-12 bg-primary rotate-90">Development</div> */}
      </div>
    </>
  );
};

export default LandingPage;
