import React from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useEffect } from "react";
import OrganizationHome from "./pages/Organization/index";
import Signup from "./pages/Auth/signup";
import Login from "./pages/Auth/Login";
import Auth from "./pages/Auth/Auth";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import VerifyAccount from "./pages/Auth/VerifyAccount";
import ResetPassword from "./pages/Auth/ResetPassword";
// applicant
import ApplicantDocumentView from "./pages/Applicant/ApplicantDocumentView";
import Applicant from "./pages/Applicant/Applicant";
import ApplicantProfile from "./pages/Applicant/ApplicantProfile";

import Home from "./pages/Home";
import EmployerProfile from "./pages/EmployerProfile";
import License from "./pages/License";
import Tips from "./pages/Tips";
import ApplicationDetail from "./pages/ApplicationDetail";
import ApplicationsList from "./pages/ApplicationsList";
import PageNotFound from "./pages/PageNotFound";
import Dashboard from "./pages/Dashboard/Dashboard";
import Verified from "./pages/Dashboard/Verified";
import Failed from "./pages/Dashboard/Failed";
import Canceled from "./pages/Dashboard/Canceled";
import Renewal from "./pages/Dashboard/Renewal";
import Placement from "./pages/Dashboard/Placement";
import Blacklist from "./pages/Dashboard/Blacklist";
import Organization from "./pages/Dashboard/Organization";
import OrganizationProfile from "./pages/Dashboard/individual/OrganizationProfile";
import DashboardLayout from "./layouts/DashboardLayout";
import History from "./pages/Dashboard/History";
import AdditionalInfo from "./pages/Dashboard/Additional-Info";
import Settings from "./pages/Dashboard/Settings";
import Archive from "./pages/Dashboard/Archive";

import NewApplicationRequest from "./pages/Organization/NewApplication/NewApplicationRequest";
import OrganizationLayout from "./layouts/OrganizationLayout";
import CreateOrganizationProfile from "./pages/Organization/CreateOrganizationProfile";
import Profile from "./pages/Organization/Profile";
import VerifiedOrganizationWorkPermit from "./pages/Organization/VerifiedOrganizationWorkPermit";
import FailedOrganizationWorkPermit from "./pages/Organization/FailedOrganizationWorkPermit";
import CanceledOrganizationWorkPermit from "./pages/Organization/CancellationOrganizationWorkPermit";
import RenewOrganizationWorkPermit from "./pages/Organization/RenewOrganizationWorkPermit";
import IncomingOrganization from "./pages/Dashboard/IncomingOrganization";
import RequestedOrganizationWorkPermit from "./pages/Organization/RequestedPermits/RequestedOrganizationWorkPermit";
import ChangePassword from "./pages/Dashboard/ChangePassword";
import { roles } from "./utils/roles";
import { Can } from "./permission/Can";
import CustomRoutes from "./routes/index";
import SwitchOrganization from "./pages/Organization/SwitchOrganization";
import DetailOrganization from "./pages/Organization/RequestedPermits/DetailOrganization";
import UpdateOrganizationProfile from "./pages/Organization/UpdateOrganizationProfile";
import EmployeList from "./pages/Dashboard/SystemAdmin/EmployeList";
import FIleUploadPage from "./pages/Dashboard/SystemAdmin/FIleUploadPage";
import AddNewEmployee from "./pages/Dashboard/SystemAdmin/AddNewEmployee";
import StatusShow from "./pages/Dashboard/SystemAdmin/StatusShow";
import RenewalDetail from "./pages/Organization/Renewal/RenewalDetail";
import UserList from "./pages/Dashboard/Manager/UserList";
// import ProtectedRoute from "./routes/protectedRoute";
import ReplacementWorkPermit from "./pages/Organization/ReplacementWorkPermit";
import ImportDocument from "./pages/Organization/RequestedPermits/ImportDocument";
import RequestRenewalApplication from "./pages/Organization/Renewal/RequestRenewalApplication";
import EmployeeDetail from "./pages/Dashboard/Manager/EmployeeDetail";
import NotificationView from "./pages/NotificationView";
import NotAuthorized from "./pages/NotAuthorized";
import CancellationDetail from "./pages/Organization/Cancelation/CancellatioinRequestForm";
import CancellationDocumentView from "./pages/Dashboard/Cancellation/CancellationDocumentView";
import DataChangePage from "./pages/Dashboard/SystemAdmin/DataChangePage";
import ProtectedRoute from "./routes/protectedRoute";
import IDPreview from "./pages/Applicant/IDPreview";
import TestPage from "./pages/test";
import ReportPage from "./pages/Report/ReportPage";
import ReportLayout from "./layouts/ReportLayout";
import ReportDashboard from "./pages/Report";
import ReportDetailOperation from "./pages/Report/reportDetailOperation";
import EmployeePage from "./pages/Dashboard/Manager/EmployeePages";
import ReplacementPage from "./pages/Organization/Replacment";
import OrganizationRenewal from "./pages/Organization/Renewal/OrganizationRenewal";
import RenewalOrganizationList from "./pages/Dashboard/RenewalOrganizationList";
import SwapExpatriateApplication from "./pages/Organization/NewApplication/SwapExpatriateApplication";
import TransferExpatriate from "./pages/Organization/NewApplication/TransferExpatriate";

function App() {
  // const { user, login, logout } = useAuth();
  let userLogged;
  const location = useNavigate();

  const isAuthenticated = true; // replace with your authentication logic
  const userRoles = Object.values(roles); // replace with your user roles array
  // console.log(userRoles);
  const hasAllowedRole = (allowedRoles) => {
    return allowedRoles.some((role) => userRoles.includes(role));
  };

  const renderProtectedComponent = (component, allowedRoles) => {
    if (isAuthenticated && hasAllowedRole(allowedRoles)) {
      return component;
    } else {
      return <Navigate to="/403" />;
    }
  };

  useEffect(() => {
    userLogged = localStorage.getItem("work-permit");
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.error = function () {};
    }
  });

  const handleDenied = () => {
    // redirect to 403 page
    window.location.href = "/403";
  };

  return (
    // <CustomRoutes />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/test" element={<TestPage />} />
      <Route path="/permit/d/:id" element={<IDPreview />} />
      <Route path="/employer-profile" element={<EmployerProfile />} />
      {/* <ProtectedRoute
            component={DashboardLayout}
            allowedRoles={[
              roles.WP_ARCHIVE_OPERATOR,
              roles.WP_DEP_MINISTER,
              roles.WP_DESK_HANDLER,
              roles.WP_DESK_MANAGER,
              roles.WP_DOC_CHECKER,
              roles.WP_EMR_GENERATOR,
              roles.WP_FINANCE,
              roles.WP_REG_ADMIN,
              roles.WP_SYS_ADMIN,
            ]}
            path="/dashboard/*"
            element={<DashboardLayout />}
          > */}

      {/* <React.Fragment> */}
      <Route path="/dashboard/*" element={<DashboardLayout />}>
        <Route
          path=""
          element={<Dashboard />}
          // element={
          //   <ProtectedRoute
          //     path=""
          //     component={Dashboard}
          //     permission={{ I: "view", a: "dashboard" }}
          //   />
          // }
        />

        <Route path="change-password" element={<ChangePassword />} />
        <Route
          path="user-list"
          element={<UserList /> }
          // element={
          //   <ProtectedRoute
          //     path="user-list"
          //     component={UserList}
          //     permission={{ I: "view", a: "user-list" }}
          //   />
          // }
        />
        <Route path="employees" element={<EmployeePage />}/>
        <Route
          path="user-list/detail/:id"
          element={<EmployeeDetail />}
          // element={
          //   <ProtectedRoute
          //     path="user-list/detail/:id"
          //     component={EmployeeDetail}
          //     permission={{ I: "view", a: "employees" }}
          //   />
          // }
        />
        {/* <Route path="report" element={<ReportPage />}/> */}
        <Route
          path="employee-list"
          element={
            <ProtectedRoute
              path="employee-list"
              permission={{ I: "view", a: "employees" }}
              component={EmployeList}
            />
          }
        />
        <Route
          path="employee/new"
          element={
            <ProtectedRoute
              path={"employee/new"}
              permission={{ I: "view", a: "employees" }}
              component={AddNewEmployee}
            />
          }
        />
        <Route
          path="status-check"
          element={
            <ProtectedRoute
              path="status-check"
              permission={{ I: "view", a: "status" }}
              component={StatusShow}
            />
          }
        />
        <Route
          path="applicant"
          element={<Applicant />}
          // element={
          //   <ProtectedRoute
          //     path="applicant"
          //     permission={{ I: "view", a: "applicant" }}
          //     component={Applicant}
          //   />
          // }
        />
        <Route path="file-upload" element={<FIleUploadPage />} />
        <Route
          path="applicant/document/:id"
          element={<ApplicantDocumentView />}
          // element={
          //   <ProtectedRoute
          //     path={"applicant/document/:id"}
          //     component={ApplicantDocumentView}
          //     permission={{ I: "view", a: "viewApplicant" }}
          //   />
          // }
        />
        <Route
          path="verified"
          element={<Verified />}
          // element={
          //   <ProtectedRoute
          //     path={"verified"}
          //     component={Verified}
          //     permission={{ I: "view", a: "verified" }}
          //   />
          // }
        />
        <Route path="applicant/profile/:id" element={<ApplicantProfile />} />
        <Route
          path="organization/profile/:id"
          element={<OrganizationProfile />}
        />
        <Route path="organization" element={<IncomingOrganization />} />
        <Route path="organization/p/renewal" element={<RenewalOrganizationList/>} />
        <Route path="organization/detail/:id" element={<Organization />} />
        {/* <Route path="verified" element={<Verified />} /> */}
        <Route path="failed" element={<Failed />} />
        <Route path="renewal" element={<Renewal />} />
        <Route path="cancelled" element={<Canceled />} />
        <Route
          path="cancelled/document/:id"
          element={<CancellationDocumentView />}
        />
        <Route path="replacement" element={<Placement />} />
        <Route path="blacklist" element={<Blacklist />} />
        <Route path="settings" element={<Settings />} />
        <Route path="additional-info" element={<AdditionalInfo />} />
        <Route path="history" element={<History />} />
        <Route path="data-change" element={<DataChangePage />} />
        {/* </ProtectedRoute> */}
      </Route>

      <Route path="/report/*" element={<ReportLayout />}>
          <Route path="" element={<ReportDashboard />}/>
          <Route path="dashboard" element={<ReportPage />}/>
          <Route path="detail/employee/:id" element={<ReportDetailOperation />}/>
      </Route>
      <Route path="archive" element={<Archive />} />
      <Route path="/organization/*" element={<OrganizationLayout />}>
        <Route path="" element={<OrganizationHome />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route
          path="requested-work-permit"
          element={<RequestedOrganizationWorkPermit />}
        />
        <Route
          path="requested-work-permit/detail/:id"
          element={<DetailOrganization />}
        />
        <Route
          path="requested-work-permit/detail/:id/upload-file"
          element={<ImportDocument />}
        />
        <Route
          path="verified-work-permit"
          element={<VerifiedOrganizationWorkPermit />}
        />
        <Route
          path="failed-work-permit"
          element={<FailedOrganizationWorkPermit />}
        />
        <Route
          path="canceled-work-permit"
          element={<CanceledOrganizationWorkPermit />}
        />
        <Route
          path="replacement-work-permit"
          element={<ReplacementWorkPermit />}
        />
        <Route
          path="replacement-work-permit/n/request/:id"
          element={<ReplacementPage />}
        />
        <Route
          index
          path="renew-work-permit"
          element={<RenewOrganizationWorkPermit />}
        />

        <Route
          index
          path="cancellation/detail/:id"
          element={<CancellationDetail />}
        />
        <Route index path="renewal/detail/:id" element={<RenewalDetail />} />
        <Route index path="blacklist-request" element={<OrganizationHome />} />
      </Route>
      <Route index path="workspace" element={<SwitchOrganization />} />
      <Route
        path="/organization/new-work-request"
        element={<NewApplicationRequest />}
      />
      <Route
        path="/organization/sw/new-work-request"
        element={<TransferExpatriate />}
      />
      <Route
        path="/organization/sw/new-work-request/:id"
        element={<SwapExpatriateApplication />}
      />
       <Route path="organization/renewal/:id" element={<OrganizationRenewal />} />
      <Route
        index
        path="/organization/renew-work-permit/request"
        element={<RequestRenewalApplication />}
      />
      <Route
        index
        path="/create-organization-profile"
        element={<CreateOrganizationProfile />}
      />
      <Route
        index
        path="/update-organization-profile/update/:id"
        element={<UpdateOrganizationProfile />}
      />
      <Route index path="/profile" element={<Profile />} />

      <Route path="/notifications" element={<NotificationView />} />
      {/* </ProtectedRoute> */}

      <Route path="/auth" element={<Auth />} />
      <Route path="/login" element={<Login />} />
      <Route path="/new-login" element={<Auth />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/verify-account" element={<VerifyAccount />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/license" element={<License />} />
      <Route path="/tips" element={<Tips />} />
      <Route path="/detail" element={<ApplicationDetail />} />
      <Route path="/application-list" element={<ApplicationsList />} />
      <Route path="/unauthorized" element={<NotAuthorized />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
