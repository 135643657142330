import React, { useEffect, useState } from "react";
import PDFReader from "../pdf/PDFReader";
import ImageViewer from "../ImageViewer";
import { BANK_SLIP_ID } from "../../utils/constants";
import { Can } from "../../permission/Can";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";
import Button from "../common/Button";
import { getImageUrl } from "../common/imageUtils";

const DocumentViewerDrawer = ({
  selectedDocument,
  handleCloseDrawer,
  handleRejection,
  handleVerify,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    const fetchImageUrl = async () => {
      if (selectedDocument?.files) {
        const url = await getImageUrl(selectedDocument.files);
        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [selectedDocument?.files]);
  const copyDivToClipboard = () => {
    const range = document.createRange();
    range.selectNode(document.getElementById("copySelector"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    toast.success("Reference Number Copied!", selectedDocument?.reference_number);
  };

  return (
    <div
      style={{ background: "rgba(0,0,0,0.9)" }}
      className="z-50 fixed top-0 left-0 h-screen w-full flex items-center justify-center"
    >
      <div
        id="drawer-body-scrolling"
        className="fixed top-0 right-full md:right-2/4 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-full md:w-2/4 dark:bg-gray-800"
        tabIndex="-1"
        aria-labelledby="drawer-body-scrolling-label"
      >
        <div className="bg-blue-950 p-4">
          <h5
            id="drawer-body-scrolling-label"
            className="text-base font-semibold text-gray-500 uppercase dark:text-gray-400"
          >
            {selectedDocument?.document_type?.name_json?.en}{" "}
            <span
              className={`${
                selectedDocument?.review_status === true
                  ? "bg-green text-white"
                  : selectedDocument?.review_status === false
                  ? "bg-red10 text-fuchsia-50"
                  : "bg-yellow30 text-white"
              } px-2 py-0.5 rounded text-xs bg-blue-100`}
            >
              {selectedDocument?.review_status === true
                ? "Approved"
                : selectedDocument?.review_status === false
                ? "Rejected"
                : "Pending"}
            </span>
          </h5>
          {selectedDocument?.document_type?.id !== BANK_SLIP_ID && (
            <Can I={"action"} a={"documentCheck"}>
              {() => (
                <div className="mt-8 flex flex-wrap gap-2">
                  <Button
                    handleClick={handleRejection}
                    custom={`${
                      selectedDocument?.review_status === false
                        ? "pointer-events-none opacity-40"
                        : ""
                    } bg-red text-white rounded py-1.5 text-sm`}
                  >
                    Reject Document
                  </Button>
                  <Button
                    handleClick={handleVerify}
                    custom={`${
                      selectedDocument?.review_status === true
                        ? "pointer-events-none opacity-40"
                        : ""
                    } bg-primary text-white rounded py-1.5 text-sm`}
                  >
                    Verify Document
                  </Button>
                </div>
              )}
            </Can>
          )}
          <Can I={"action"} a={"reference"}>
            {() => (
              <div id="copySelector" className="mt-4 w-full flex">
                <div className="w-4/5 bg-prime40/10 p-3 rounded-l text-primary">
                  {selectedDocument?.reference_number}
                </div>
                <div
                  onClick={copyDivToClipboard}
                  className="bg-primary w-1/5 flex justify-center items-center text-white p-2 rounded-r text-xl cursor-pointer"
                >
                  <BiCopy />
                </div>
              </div>
            )}
          </Can>
          <button
            type="button"
            data-drawer-hide="drawer-body-scrolling"
            aria-controls="drawer-body-scrolling"
            onClick={handleCloseDrawer}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
        </div>
        <div className="py-4 overflow-y-auto">
          {selectedDocument?.review_status === false &&
            selectedDocument?.review_note && (
              <div className="border-2 p-4 mb-2 border-red rounded-md w-full">
                <h5 className="font-semibold text-gray-100">
                  Document Review Comment{" "}
                </h5>
                <p className="text-red">
                  {selectedDocument?.review_note}
                </p>
              </div>
            )}
          {selectedDocument?.files?.split(".").pop() === "pdf" ? (
            <PDFReader pdfData={imageUrl} />
          ) : (
            <ImageViewer imageUrl={imageUrl} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentViewerDrawer;
