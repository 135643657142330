import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PieChart from "../../components/chart/PieChart";
import AreaChart from "../../components/chart/AreaChart";
import { FiExchangeVertical } from "react-icons/fi";
import StasticCard from "../../components/chart/StatsticCard";
import { useLazyQuery } from "@apollo/client";
import {
  CHECKER_DAILY_REPORT,
  CHECKER_DASHBOARD_REPORT,
  EMR_DAILY_DASHBOARD_REPORT,
  EMR_DASHBOARD_REPORT,
  PERMIT_DASHBOARD_REPORT,
  TOTAL_EXPT,
  TOTAL_EXPT_FILTER,
} from "../../graph-query/queries";
import { useLocalStore } from "../../store";
import { WORK_PERMIT_TYPES } from "../../utils/constants";
import { roles } from "../../utils/roles";
import { FaFileAlt } from 'react-icons/fa';
import { Can } from "../../permission/Can";
import { useReportStore } from "../../store/reportStore";
import DonutChart from "../../components/chart/DountChart";

const Dashboard = ({ children }) => {
  const {
    currentRole,
    totalExpatrates,
    totalNewPermits,
    totalRenwalPermits,
    totalCancelledPermits,
    setTotalExpatrates,
    setTotalNewPermits,
    setTotalRenwalPermits,
    setTotalCancelledPermits,
    totalReport,
    setTotalReport,
    setDailyTotalReport,
    dailyTotalReport,
  } = useLocalStore();

  const router = useNavigate();

  const permits = [
    {
      type: WORK_PERMIT_TYPES.NEW,
      data: totalNewPermits,
      setFunc: setTotalNewPermits,
    },
    {
      type: WORK_PERMIT_TYPES.RENEWAL,
      data: totalRenwalPermits,
      setFunc: setTotalRenwalPermits,
    },
    {
      type: WORK_PERMIT_TYPES.CANCELLATION,
      data: totalCancelledPermits,
      setFunc: setTotalCancelledPermits,
    },
  ];

  const [getTotalExp, {}] = useLazyQuery(TOTAL_EXPT, {
    onCompleted: (d) => {
      setTotalExpatrates(d?.expatriate_work_permits_aggregate);
    },
    onError: (er) => console.log(er),
  });

  //Get Data for Each permit types
  const [getPermitData, { loading, error }] = useLazyQuery(TOTAL_EXPT_FILTER, {
    onCompleted: async (res) => {
      await permits.forEach(async (permit) => {
        await permit.setFunc(res?.expatriate_work_permits_aggregate);
      });
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    permits.forEach((permit) => {
      if (permit?.setFunc && !permit?.data) {
        getPermitData({ variables: { type: permit.type } });
      }
    });
  }, [getPermitData]);

  useEffect(() => {
    switch (currentRole) {
      case roles.WP_DOC_CHECKER:
        getTotalExp();
        break;
      case roles.WP_FINANCE:
        getTotalExp();
        break;

      default:
        break;
    }
  }, [totalExpatrates, setTotalExpatrates]);

  /**
   * @description EMR Report
   */
  const [getEMRReport, {}] = useLazyQuery(EMR_DASHBOARD_REPORT, {
    onCompleted(data) {
      setTotalReport(data);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getEMRDailyReport, {}] = useLazyQuery(EMR_DAILY_DASHBOARD_REPORT, {
    onCompleted(data) {
      setDailyTotalReport(data);
    },
    onError(err) {
      console.log(err);
    },
  });

  /**
   * @description DOCUMENT Checker
   */
  const [getCheckerReport, {}] = useLazyQuery(CHECKER_DASHBOARD_REPORT, {
    onCompleted(data) {
      setTotalReport(data);
    },
    onError(err) {
      console.log(err);
    },
  });
  const [getCheckerDailyReport, {}] = useLazyQuery(CHECKER_DAILY_REPORT, {
    onCompleted(data) {
      setDailyTotalReport(data);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getPermitReport, {}] = useLazyQuery(PERMIT_DASHBOARD_REPORT, {
    onCompleted(data) {
      setTotalReport(data);
    },
    onError(er) {
      console.log(er);
    },
  });
  useEffect(() => {
    if (currentRole === roles.WP_EMR_GENERATOR) {
      getEMRReport({
        variables: {
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
      getEMRDailyReport({
        variables: {
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
          weekStartDate: `${
            new Date().toISOString().split("T")[0]
          }T00:00:00.000Z`,
          weekEndDate: `${
            new Date().toISOString().split("T")[0]
          }T23:59:59.999Z`,
        },
      });
    }

    if (currentRole === roles.WP_DOC_CHECKER) {
      getCheckerReport({
        variables: {
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
      getCheckerDailyReport({
        variables: {
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
          weekStartDate: `${
            new Date().toISOString().split("T")[0]
          }T00:00:00.000Z`,
          weekEndDate: `${
            new Date().toISOString().split("T")[0]
          }T23:59:59.999Z`,
        },
      });
    }
  }, [
    currentRole === roles.WP_EMR_GENERATOR ||
      currentRole === roles.WP_DOC_CHECKER,
  ]);

  useEffect(() => {
    getPermitReport();
  }, [currentRole === roles.WP_DESK_HANDLER || currentRole === roles.WP_DESK_MANAGER]);

  let _mockData = [
    {
      id: 1,
      title: "New Applicants",
      number: 0,
    },
    {
      id: 2,
      title: "Verified Applicants",
      number: 0,
    },
    {
      id: 3,
      title: "Failed Accounts",
      number: 0,
    },

    {
      id: 4,
      title: "Renewal Request",
      number: 0,
    },
  ];

  return (
    <>
      <div>
        <Can I={"generate"} a={"report"}>
          {() => {
            <button
              type="button"
              className="text-gray-200 bg-primary hover:bg-gray-100 border border-blue-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
            >
              <FaFileAlt size={20} />
              Generate Weekly Report
            </button>;
          }}
        </Can>
      </div>
      <div className="grid md:grid-cols-4 sm:grid-cols-1 gap-4">
        <div className="relative col-span-2 row-span-2 justify-center items-center w-full p-8 border-2 bg-white shadow-lg rounded-lg">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <span className="text-gray-400 text-xs font-semibold uppercase tracking-wide">
                Total Report
              </span>
              {
                <h1 className="text-5xl font-bold leading-tight text-gray-900 mt-2">
                  {currentRole === roles.WP_DOC_CHECKER
                    ? dailyTotalReport?.total_assigned?.aggregate?.count +
                      dailyTotalReport?.total_organization?.aggregate?.count
                    : dailyTotalReport?.total_assigned?.aggregate?.count}
                </h1>
              }
              <div className="mt-4">
                <span className="text-lg font-semibold text-gray-700 tracking-wide">
                  Daily total Stat
                </span>
              </div>
            </div>
            <div className="border-t pt-6">
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <span className="text-sm text-gray-600 font-semibold tracking-wide">
                    New Applicant
                  </span>
                  <p className="text-xl font-bold text-gray-900 mt-1">
                    {dailyTotalReport?.total_newPermit?.aggregate?.count}
                  </p>
                </div>
                <div>
                  <span className="text-sm text-gray-600 font-semibold tracking-wide">
                    Organization
                  </span>
                  <p className="text-xl font-bold text-gray-900 mt-1">
                    {dailyTotalReport?.total_organization?.aggregate?.count}
                  </p>
                </div>
                <div>
                  <span className="text-sm text-gray-600 font-semibold tracking-wide">
                    Cancellation
                  </span>
                  <p className="text-xl font-bold text-gray-900 mt-1">
                    {dailyTotalReport?.total_cancelledPermit?.aggregate?.count}
                  </p>
                </div>
                <div>
                  <span className="text-sm text-gray-600 font-semibold tracking-wide">
                    Renewal
                  </span>
                  <p className="text-xl font-bold text-gray-900 mt-1">
                    {dailyTotalReport?.total_renewalPermit?.aggregate?.count}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Total applicant stat */}
        <div className="h-40 w-full px-4 py-6 border-2 bg-white shadow-lg rounded-lg flex flex-col justify-between">
          <span className="text-gray-400 text-xs font-semibold uppercase tracking-wide">
            New Application
          </span>
          <h1 className="text-4xl font-bold leading-tight text-gray-900 mt-2">
            {totalReport?.total_newPermit?.aggregate?.count}
          </h1>
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <span className="text-sm text-gray-600 font-semibold tracking-wide">
                --
              </span>
            </div>
            <button
              onClick={() => router("/dashboard/applicant")}
              className="px-4 py-2 text-sm font-medium text-gray-400 bg-transparent hover:bg-primary hover:bg-opacity-10 rounded-md transition-colors"
            >
              View details
            </button>
          </div>
        </div>
        <div className="h-40 w-full px-4 py-6 border-2 bg-white shadow-lg rounded-lg flex flex-col justify-between">
          <span className="text-gray-400 text-xs font-semibold uppercase tracking-wide">
            Renewal Application
          </span>
          <h1 className="text-4xl font-bold leading-tight text-gray-900 mt-2">
            {totalReport?.total_renewalPermit?.aggregate?.count}
          </h1>
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <span className="text-sm text-gray-600 font-semibold tracking-wide">
                --
              </span>
            </div>
            <button
              onClick={() => router("/dashboard/renewal")}
              className="px-4 py-2 text-sm font-medium text-gray-400 bg-transparent hover:bg-primary hover:bg-opacity-10 rounded-md transition-colors"
            >
              View details
            </button>
          </div>
        </div>
        <div className="h-40 w-full px-4 py-6 border-2 bg-white shadow-lg rounded-lg flex flex-col justify-between">
          <span className="text-gray-400 text-xs font-semibold uppercase tracking-wide">
            Cancellation Application
          </span>
          <h1 className="text-4xl font-bold leading-tight text-gray-900 mt-2">
            {totalReport?.total_cancelledPermit?.aggregate?.count}
          </h1>
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <span className="text-sm text-gray-600 font-semibold tracking-wide">
                --
              </span>
            </div>
            <button
              onClick={() => router("/dashboard/cancelled")}
              className="px-4 py-2 text-sm font-medium text-gray-400 bg-transparent hover:bg-primary hover:bg-opacity-10 rounded-md transition-colors"
            >
              View details
            </button>
          </div>
        </div>
        <div className="h-40 w-full px-4 py-6 border-2 bg-white shadow-lg rounded-lg flex flex-col justify-between">
          <span className="text-gray-400 text-xs font-semibold uppercase tracking-wide">
            Organization
          </span>
          <h1 className="text-4xl font-bold leading-tight text-gray-900 mt-2">
            {totalReport?.total_organization?.aggregate?.count}
          </h1>
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <span className="text-sm text-gray-600 font-semibold tracking-wide">
                --
              </span>
            </div>
            <button
              onClick={() => router("/dashboard/organization")}
              className="px-4 py-2 text-sm font-medium text-gray-400 bg-transparent hover:bg-primary hover:bg-opacity-10 rounded-md transition-colors"
            >
              View details
            </button>
          </div>
        </div>
      </div>

      <div className="w-full grid md:grid-cols-2 grid-cols-1 mt-5 items-stretch gap-4">
        {totalReport && (
          <DonutChart
            data={[
              totalReport?.total_newPermit?.aggregate?.count,
              totalReport?.total_renewalPermit?.aggregate?.count,
              totalReport?.total_cancelledPermit?.aggregate?.count,
            ]}
          />
        )}
        <AreaChart
          areaCatagory={[
            "2018-09-19T00:00:00.000Z",
            "2018-09-19T01:30:00.000Z",
            "2018-09-19T02:30:00.000Z",
            "2018-09-19T03:30:00.000Z",
            "2018-09-19T04:30:00.000Z",
            "2018-09-19T05:30:00.000Z",
            "2018-09-19T06:30:00.000Z",
          ]}
          areaData={[
            {
              name: "series1",
              data: [31, 40, 28, 51, 42, 109, 100],
            },
            {
              name: "series2",
              data: [11, 32, 45, 32, 34, 52, 41],
            },
          ]}
        />
      </div>

      <div class="col-md-4">
        <div class="box box1">
          {/* <div id="spark1"></div> */}
          {/* <StasticCard /> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
