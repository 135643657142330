import React, { useState } from "react";
import {
  MdDownload,
  MdOpenInBrowser,
  MdOpenInNew,
  MdRotateRight,
  MdScale,
  MdZoomIn,
  MdZoomOut,
} from "react-icons/md";
import { useRef } from "react";
import { useEffect } from "react";

const ImageViewer = ({ imageUrl }) => {
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [position, setPosition] = useState({
    oldX: 0,
    oldY: 0,
    x: 0,
    y: 0,
    z: 1,
  });
  const [isPanning, setPanning] = useState(false);
console.log(imageUrl,"imageUrl")
 
  const containerRef = useRef();

  const handleZoomIn = () => {
    setScale((prev) => prev + (0.4987654321 * scale) / Math.abs(scale));
  };

  const handleZoomOut = () => {
    setScale((prev) => prev - (0.4987654321 * scale) / Math.abs(scale));
  };

  const handleRotate = () => {
    console.log("rotation: ", rotation);
    setRotation((rotation + 90) % 360);
  };

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setDimensions({ width: naturalWidth, height: naturalHeight });
  };

  useEffect(() => {
    const mouseup = () => {
      setPanning(false);
    };
    const mousemove = (event) => {
      if (isPanning) {
        setPosition({
          ...position,
          x: position.x + event.clientX - position.oldX,
          y: position.y + event.clientY - position.oldY,
          oldX: event.clientX,
          oldY: event.clientY,
        });
      }
    };
    window.addEventListener("mouseup", mouseup);
    window.addEventListener("mousemove", mousemove);
    return () => {
      window.removeEventListener("mouseup", mouseup);
      window.removeEventListener("mousemove", mousemove);
    };
  });

  const onMouseDown = (e) => {
    e.preventDefault();
    setPanning(true);
    setPosition({
      ...position,
      oldX: e.clientX,
      oldY: e.clientY,
    });
  };
  const onWheel = (e) => {
    if (e.deltaY) {
      const sign = Math.sign(e.deltaY) / 10;
      const scale = 1 - sign;
      const rect = containerRef.current.getBoundingClientRect();
      setPosition({
        ...position,
        x: position.x * scale - (rect.width / 2 - e.clientX + rect.x) * sign,
        y:
          position.y * scale -
          ((imageUrl.height * rect.width) / imageUrl.width / 2 -
            e.clientY +
            rect.y) *
            sign,
        z: position.z * scale,
      });
    }
  };

  return (
    <div
      ref={containerRef}
      onMouseDown={onMouseDown}
      onWheel={onWheel}
      className="flex gap-2 flex-col relative"
    >
      <div className="absolute left-4 top-5 p-4 bg-blue-100 rounded-lg flex flex-col gap-2 z-50">
        <button
          onClick={handleZoomIn}
          type="button"
          className="bg-blue-300 p-3 rounded-full"
        >
          <MdZoomIn color="#000" size={20} />
        </button>
        <button
          onClick={handleZoomOut}
          type="button"
          disabled={scale === 1}
          className="disabled:opacity-50 disabled:pointer-events-none bg-blue-300 p-3 rounded-full"
        >
          <MdZoomOut color="#000" size={20} />
        </button>
        <button
          onClick={handleRotate}
          type="button"
          className="disabled:opacity-50 disabled:pointer-events-none bg-blue-300 p-3 rounded-full"
        >
          <MdRotateRight color="#000" size={20} />
        </button>
        <button
          onClick={() => {
            const link = document.createElement("a");
            link.href = imageUrl;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.click();
          }}
          type="button"
          className="bg-blue-300 p-3 rounded-full"
        >
          <MdDownload />
        </button>
        <button
          onClick={() => {
            const link = document.createElement("a");
            link.href = imageUrl;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.click();
          }}
          type="button"
          className="bg-blue-300 p-3 rounded-full"
        >
          <MdOpenInNew scale={22} />
        </button>
      </div>
      <img
        onLoad={handleImageLoad}
        style={{
          transform: `rotate(${rotation}deg) scale(${scale})`,
          minHeight: "80vh",
          objectFit: "contain",
        }}
        // className={`${getPageClassName()} object-contain`}
        src={imageUrl && imageUrl}
        alt="image view"
      />

      {/* <p>Image width: {dimensions.width}</p>
      <p>Image height: {dimensions.height}</p> */}
    </div>
  );
};

export default ImageViewer;
