import React, { useState, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import logo from "../../assets/images/mols-logo.png";
import { useLazyQuery } from "@apollo/client";
import { GET_WORK_PERMIT_BYID } from "../../graph-query/queries";
import { useParams } from "react-router-dom";
import { GetLatestProfilePic } from "../../utils/utilityFunctions";
import { getImageUrl } from "../../components/common/imageUtils";

const smooth = { stiffness: 0.066, damping: 0.25 };
// const rotate = spring({ x: 0, y: 0 }, smooth);

const round = (num, fix = 3) => parseFloat(num.toFixed(fix));

function getMousePosition(e) {
  if ("touches" in e) {
    return {
      x: e.touches?.[0]?.clientX,
      y: e.touches?.[0]?.clientY,
    };
  } else {
    return {
      x: e.clientX,
      y: e.clientY,
    };
  }
}

const IDPreview = () => {
  const [userData, setUserData] = useState();
  const [isInteracting, setIsInteracting] = useState(false);
  const [rotation, setRotation] = useState({ x: 0, y: 0 });

  const { id } = useParams();
  let fullName = "";

  const { transform } = useSpring({
    transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const interact = (e) => {
    if (!isInteracting) setIsInteracting(true);
    setRotation({
      x: (e.clientY / window.innerHeight) * 180 - 90,
      y: (e.clientX / window.innerWidth) * 360 - 180,
    });
  };

  const interactEnd = () => {
    if (isInteracting) setIsInteracting(false);
    setRotation({ x: 0, y: 0 });
  };

  const [getUserInfo, { loading, error }] = useLazyQuery(GET_WORK_PERMIT_BYID, {
    onCompleted: (data) => {
      setUserData(data?.expatriate_work_permits[0]);
    },
    onError: (er) => console.log(er),
  });

  useEffect(() => {
    getUserInfo({ variables: { id: id } });
    fullName = `${userData?.expatriate?.first_name || ""} ${
      userData?.expatriate?.father_name || ""
    } ${userData?.expatriate?.grand_father_name || ""}`;
  }, [id, getUserInfo]);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (GetLatestProfilePic(userData)) {
        const url = await getImageUrl(GetLatestProfilePic(userData));
        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [GetLatestProfilePic(userData)]);
  return (
    <div className="card flex justify-center items-center h-screen">
      <animated.div
        className="card-inner bg-slate-50 shadow-xl rounded-2xl h-3/6 max-w-5xl w-3/4"
        style={{
          transform: "scale(var(--scale)) rotateY(var(--rotateDelta))",
          transformStyle: "preserve-3d",
        }}
        onMouseMove={interact}
        onMouseOut={interactEnd}
        onBlur={interactEnd}
      >
        <div className="w-full relative h-full bg-blend-luminosity rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-30 border border-gray-100">
          <div className="absolute top-2 left-5">
            <img src={logo} alt="" />
          </div>
          <div className="absolute top-4 right-6 flex flex-col">
            <span className="font-semibold text-gray-700">
              {userData?.emr_number}
            </span>
            <small>{userData?.expatriate?.visa_type?.name}</small>
          </div>
          <div>
            <img
              className="w-52 h-52 object-cover absolute top-[50%] left-[50%] rounded-full ring-offset-black ring-2"
              style={{ translate: "-50% -50%" }}
              src={imageUrl
              }
              alt={userData?.expatriate?.first_name}
            />
          </div>
          <div className="absolute bottom-3 left-0 right-0 flex flex-col items-center justify-center">
            <span className="text-gray-500">
              @{userData?.organization?.name}
            </span>
            <h2 className="text-4xl font-bold">
              {` ${userData?.expatriate?.first_name || ""} ${
                userData?.expatriate?.father_name || ""
              } ${userData?.expatriate?.grand_father_name || ""}`}
            </h2>
            <span>Expire date: {userData?.expiry_date}</span>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default IDPreview;
