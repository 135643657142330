import React, { useEffect, useState } from "react";
import payment from "../../assets/images/payment.jpg";
import { BiCopy } from "react-icons/bi";
import { FormProvider, CTextArea } from "../Forms/Fields";
import CButton from "../Forms/CButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import {
  REJECT_FINANCE_DOC_SLIP,
  VERIFY_EXPATRIATE_DOCUMENT,
  VERIFY_FINANCE_DOC_SLIP,
} from "../../graph-query/mutation";
import { useToaster } from "../../hooks/useToaster";
import { getImageUrl } from "../common/imageUtils";

const rejectSchema = yup
  .object({
    message: yup.string().required(),
  })
  .required();

const FinanceDocument = ({ data, handleClick, handleReject, handleVerify }) => {
  // console.log(data);

  const toast = useToaster();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(rejectSchema),
  });

  const { handleSubmit: handleSubmit2 } = useForm();
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      if ( data.files) {
        const url = await getImageUrl( data.files);
        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [ data.files]);
  const copyDivToClipboard = () => {
    var range = document.createRange();
    range.selectNode(document.getElementById("copySelector"));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges(); // to deselect
    // alert("refernce number Copied ");
    toast.InfoToast("Reference Number Copied!", data?.reference_number);
  };

  // const handleReject = async (w) => {
  //   console.log("rejecte document", w);
  //   await rejectVerify_doc({
  //     variables: {
  //       id: data?.id,
  //       review_status: false,
  //       review_note: w?.message,
  //     },
  //   }).then(() => {
  //     // toast.SuccessToast('Finance Docuemnt Verified Successfully')
  //     toast.WarnToast("Finance Document Rejected!");
  //     handleClick();
  //   });
  // };

  // const [verify_doc, { fin_data, loading: signInLoading }] = useMutation(
  //   VERIFY_EXPATRIATE_DOCUMENT,
  //   {
  //     onError(err) {
  //       const error = `${err}`.split(":").reverse()[0];
  //       console.log(error.trim());
  //     },
  //     onCompleted(d) {
  //       console.log(d);
  //     },
  //   }
  // );

  // const [rejectVerify_doc, { reject_data, loading: rejectLoad }] = useMutation(
  //   VERIFY_EXPATRIATE_DOCUMENT,
  //   {
  //     onError(err) {
  //       const error = `${err}`.split(":").reverse()[0];
  //       console.log(error.trim());
  //     },
  //     onCompleted(d) {
  //       console.log(d);
  //     },
  //   }
  // );

  // const handleVerify = async () => {
  //   console.log("Finance Docuemnt Verified Successfully", data?.id);

  //   await verify_doc({ variables: { id: data?.id, review_status: true } }).then(
  //     () => {
  //       toast.SuccessToast("Finance Docuemnt Verified Successfully");
  //     }
  //   );
  // };

  return (
    <div className=" h-screen w-full z-[60] bg-white/10 backdrop-blur-sm fixed flex items-center justify-center top-0 left-0">
      <div
        onClick={handleClick}
        className="absolute h-full w-full top-0 left-0 z-10"
      ></div>
      {data?.files ? (
        <div className="bg-white overflow-hidden rounded-md shadow w-3/5 2xl:w-1/2 z-20">
          <img
            src={
              data?.files
                ? imageUrl
                : payment
            }
            alt="payment"
            className="2xl:h-[40vh] h-[45vh] w-full object-cover"
          />
          <div className="p-4 2xl:p-8 flex gap-8 justify-between">
            <div className="w-[45%]">
              <div className="bg-prime40/10 p-4 rounded-md mb-4">
                <h2 className="text-lg font-semibold text-N20">
                  {data?.name} Payment Slip
                </h2>
                <span className="font-semibold underline">
                  {data?.organization}
                </span>
                <div className="flex text-sm items-center mb-6 w-3/4 justify-between">
                  <span>{data?.files[0]?.name}</span>
                  {/* <p className="text-xs text-N50">(250kb)</p> */}
                </div>
                <div className=" space-y-2 capitalize">
                  <a
                    href={imageUrl}
                    target="_blank"
                    className="px-4 py-2 border-2 bg-gray-300 rounded-md"
                  >
                    Open Slip
                  </a>
                  <div>
                    <h3 className="text-N40 text-xs">applied date</h3>
                    <span className="text-primary text-sm">
                      {data.created_at}
                    </span>
                  </div>
                  <div>
                    <h3 className="text-N40 text-xs">applied date</h3>
                    <span className="text-primary text-sm">
                      {data?.created_at}
                    </span>
                  </div>
                </div>
              </div>
              <h2 className="text-primary mb-2 capitalize font-medium">
                reference number
              </h2>
              <div id="copySelector" className="w-4/5 flex">
                <div className="w-4/5 bg-prime40/10 p-3 rounded-l text-primary">
                  {data?.reference_number}
                </div>
                <div className="bg-primary w-1/5 flex justify-center items-center text-white p-2 rounded-r text-xl cursor-pointer">
                  <BiCopy onClick={copyDivToClipboard} />
                </div>
              </div>
            </div>

            <div className="w-[45%] flex flex-col justify-between gap-4">
              <FormProvider
                disabled={data.verified}
                onSubmithandler={handleSubmit2(handleVerify)}
              >
                <CButton
                  disabled={data?.review_status}
                  btnLabel={
                    !data?.review_status ? "Verify" : "Already Verified"
                  }
                  type="submit"
                  classes={`${
                    data?.review_status ? "opacity-50 pointer-events-none" : ""
                  } disabled:opacity-60 bg-green shadow-xl py-2 w-full rounded-sm text-white hover:opacity-70`}
                />
              </FormProvider>
              {!data?.review_status && (
                <p>
                  Reason:{" "}
                  <span className="text-sm text-red">{data?.review_note}</span>
                </p>
              )}
              <FormProvider onSubmithandler={handleSubmit(handleReject)}>
                <CTextArea
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  label="Message"
                  name="message"
                />
                {/* <button type="submit" custom="w-full bg-N99/70 py-2 text-N60 font-medium">
                  reject
                </button> */}
                <CButton
                  type="submit"
                  btnLabel="Reject"
                  classes="w-full bg-N99/90 py-2 text-red10 font-medium hover:bg-red hover:text-white"
                />
              </FormProvider>
            </div>
          </div>
        </div>
      ) : (
        "No financial document found"
      )}
    </div>
  );
};

export default FinanceDocument;
