import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Building from "../../assets/images/Building.png";
import { useForm } from "react-hook-form";
import CButton from "../../components/Forms/CButton";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD } from "../../graph-query/mutation";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotPasswordSchema } from "../../utils/SchemaValidation";
import { CInputField, FormProvider } from "../../components/Forms/Fields";

const ForgotPassword = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const router = useNavigate();

  const [ForgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);

  const changeRoute = (path) => {
    return router(`/${path}`);
  };

  const handleForgotPass = (e) => {
    console.log(e);
    localStorage.setItem("f-phone", `251${e?.phone_number}`);
    ForgotPassword({
      variables: {
        phone: `251${e?.phone_number}`,
      },
      onCompleted: (d) => {
        if (
          d?.forgotPassword?.error === null &&
          d.forgotPassword?.message !== null
        ) {
          changeRoute("reset-password");
          toast.success("Otp Send to your phone number");
          // window.location.href = '/reset-password';
        } else {
          toast.warn(d.forgotPassword?.error);
        }
      },
      onError: (er) => {
        console.log(er);
      },
    });
  };

  return (
    <>
      {/* <div className="fixed top-1/2 p-7 text-white text-lg -left-12 bg-primary rotate-90">
        Development
      </div> */}
      <div className=" relative">
        {/* <Navbar>
          <div className="flex gap-4 2xl:gap-8 text-white">
            <Link to="/">about us</Link>
            <Link to="/">log in</Link>
          </div>
        </Navbar> */}
        <div className="fixed flex top-0 left-0 w-full h-screen">
          <div className="w-1/2 hidden bg-white md:flex items-center justify-center flex-col">
            <img src={Building} alt="permit" className="w-3/5" />
            <h2 className="text-xl text-N80 capitalize w-1/2 text-center font-medium">
              We work for our country, our country lives for us
            </h2>
          </div>

          {/* Send Email Section  */}

          <div className="md:w-1/2 w-full bg-gradient-to-b from-prime20 via-prime50 to-Prime95 flex items-center justify-center flex-col gap-6 2xl:gap-8">
            <div className="flex justify-center items-center flex-col">
              <h1 className=" text-white text-2xl w-max text-center font-medium">
                Forgot Password
              </h1>
              <p className="text-xs font-light text-white">
                Enter your email we will be sending you verification code
              </p>
            </div>
            <div className=" bg-white w-11/12 md:w-3/5 2xl:w-1/2 p-4 2xl:p-8 rounded-md shadow-md space-y-4">
              <FormProvider onSubmithandler={handleSubmit(handleForgotPass)}>
                <CInputField
                  name="phone_number"
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  type="text"
                  label="Phone Number"
                />

                <CButton
                  btnLabel={"Send"}
                  type={"submit"}
                  loading={loading}
                  classes="bg-primary hover:bg-prime50 w-full text-white py-3 rounded"
                />
              </FormProvider>

              <p className="text-xs w-full text-center">
                Login to your account?{" "}
                <div onClick={() => router("/login")}>
                  <span className="text-primary font-medium">Login</span>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
