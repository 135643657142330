// src/utils/imageUtils.js
export const getImageUrl = async (filePath) => {
    const primaryUrl = `https://storagex-api.lmis.gov.et/ewp-storage/DEFAULT/${filePath}`;
    const fallbackUrl = `https://storagez-api.lmis.gov.et/ewp-storage/DEFAULT/${filePath}`;
  
    try {
      const response = await fetch(primaryUrl, { method: "HEAD" });
      if (response.ok) {
        return primaryUrl;
      } else {
        return fallbackUrl;
      }
    } catch (error) {
      return fallbackUrl;
    }
  };
  