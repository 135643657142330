import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  APPROVED_EXPATIATES_PERMITS_RENEWAL_FILTER,
  COUNT_ASSIGNED_PERMITS,
  COUNT_NOT_ASSIGNED_PERMITS,
  COUNT_TOTAL_PERMIT_ASSGINED,
  DOCUMENT_ALL_APPROVED_WORK_PERMITS,
  EXPAT_BY_ORGANZATION,
  GET_ALL_ASSIGNED_WORK_PERMITS,
  GET_ALL_ORGANIZATION,
  GET_APPLICANT_REQUEST,
  GET_ASSIGNED_ORGANIZATION_LIST,
  GET_EXPATRIATE_LIST,
  GET_FILTERED_PERMIT,
  GET_NOT_ASSIGNED_FILTERED_PERMIT,
  GET_SEARCH_ASSIGNED_WORK_PERMITS,
  SEARCH_EXPATIATES_PERMITS_FILTER_DOCUMENT_CHECKER_FINANCE,
  SEARCH_EXPATIATES_PERMITS_RENEWAL_FILTER_DOCUMENT_CHECKER,
  SEARCH_ORGANIZATION_BG_TIN,
  SEARCH_WORK_PERMITS_FILTER,
  TOTAL_ASSIGNED_WORK_PERMIT,
  TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
  TOTAL_EXPAT_COUNT,
} from "../../graph-query/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import IdModal from "../../components/utilities/IdModal";
import BlackListModal from "../../components/utilities/BlackListModal";
import { useLocalStore } from "../../store";
import Table from "../../components/Table/CTable";
import DocumentViewerDrawer from "../../components/modals/DocumentViewerDrawer";
import { toast } from "react-toastify";
import authorize from "../../hooks/withAuthorization";
import {
  ASSIGN_WORK_PERMIT,
  VERIFY_EXPATRIATE_DOCUMENT,
  VERIFY_PERMIT,
} from "../../graph-query/mutation";
import { BANK_SLIP_ID, WORK_PERMIT_TYPES } from "../../utils/constants";
import { roles } from "../../utils/roles";
import { ApplicantColumns } from "../../datasets/applicants/applicantColumns";
import FilterAccordion from "../../components/Table/FilterAccordion";
import RejectModal from "../../components/modals/RejectModal";
import { useApplicantStore } from "../../store/applicantStore";
import { Can } from "../../permission/Can";
import FilterBox from "../../components/Forms/FilterBox";

const Applicant = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [queryName, setQueryName] = useState();
  const [notAssignedOffset, setNotAssignedOffset] = useState(0);
  const [notAssignedLimit, setNotAssignedLimit] = useState(10);

  const [organizationData, setOrganizationData] = useState([]);
  const [assignedOrgList, setAssignedOrgList] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [totalExpatList, setTotalExpatList] = useState(0);
  const [notAssignedTotal, setNotAssignedTotalPermit] = useState(0);
  const [loadingRows, setLoadingRows] = useState([]); //for assign loading button
  const [show, setShow] = useState(false);
  const [allassignedPermit, setAllAssignedPermit] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [rejectModal, setRejectModal] = useState(null);

  const [currentTab, setCurrentTab] = useState("not_assigned");
  const navigate = useNavigate();

  const [assign, {}] = useMutation(ASSIGN_WORK_PERMIT, {
    onCompleted: (res) => toast.success("Work Permit Assigned successfully"),
    onError: (er) => toast.error("Error assigning work permit"),
  });
  //==========================================================
  const {
    offset,
    limit,
    setLimit,
    setOffset,
    setWorkPermits,
    filterOptionTable,
    workPermits,
    assginedWorkPermits,
    notAssignedWorkPermits,
    setNotAssignedWorkPermits,
    currentRole,
  } = useLocalStore();

  const {
    p_limit,
    p_offset,
    r_limit,
    r_offset,
    a_limit,
    a_offset,
    pendingPermits,
    rejectedPermits,
    approvedPermit,
    selectedTab,
    totalPendingAssignedPermit,
    totalApprovedAssignedPermit,
    totalRejectedAssignedPermit,
    setTotalPendingAssignedPermit,
    setTotalApprovedAssignedPermit,
    setTotalRejectedAssignedPermit,
    setSelectedTab,
    setPendingPermits,
    setRejectedPermits,
    setApprovedPermits,
    setPendingOffset,
    setRejectedOffset,
    setApprovedOffset,
    setLimitPending,
    setLimitApproved,
    setLimitRejected,
  } = useApplicantStore();

  // ===============================DESK HANDLER====================================================
  /**
   * @description all assigned work permit for desk handler
   * @access desk:handler
   */
  const [getDataFromServer, { loading, error }] = useLazyQuery(
    GET_FILTERED_PERMIT,
    {
      variables: {
        limit: limit,
        offset: offset * limit,
        permitType: WORK_PERMIT_TYPES.NEW,
      },
      context: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      onCompleted: (data) => {
        console.log("___", data);
        setWorkPermits(data?.expatriate_work_permits);
      },
    }
  );

  const [
    getNotAssignedPermits,
    { loading: notAssignedLoading, error: notAssignedError },
  ] = useLazyQuery(GET_NOT_ASSIGNED_FILTERED_PERMIT, {
    variables: {
      limit: notAssignedLimit,
      offset: notAssignedOffset * notAssignedLimit,
      permitType: WORK_PERMIT_TYPES.NEW,
    },
    context: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    onCompleted: (data) => {
      setNotAssignedWorkPermits(data?.expatriate_work_permits);
    },
  });

  const [countNotAssigned, {}] = useLazyQuery(COUNT_NOT_ASSIGNED_PERMITS, {
    onCompleted: (d) => {
      setNotAssignedTotalPermit(
        d?.expatriate_work__permits_aggregate?.aggregate?.count
      );
    },
  });

  const [countExpat, {}] = useLazyQuery(COUNT_ASSIGNED_PERMITS, {
    onCompleted: (d) => {
      setTotalExpatList(d?.expatriate_work_permits_aggregate?.aggregate?.count);
    },
  });

  // =======================================================================================

  /**
   * @description get Total count for assigned user
   * @access DOC:checker | finance
   */
  const [getTotalAssignedPermitsPending, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setTotalPendingAssignedPermit(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );
  const [getTotalAssignedPermitsApproved, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        checkerStatus: true,
      },
      onCompleted: (d) => {
        setTotalApprovedAssignedPermit(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );
  const [getTotalAssignedPermitsRejected, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        checkerStatus: false,
      },
      onCompleted: (d) => {
        setTotalRejectedAssignedPermit(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );

  /**
   * @description get permit for assigned user
   * @access doc:Checker | finance
   */
  const [getAllPendingPermitsDoc, { loading: pendingLoading }] = useLazyQuery(
    GET_ALL_ASSIGNED_WORK_PERMITS,
    {
      variables: {
        limit: Number(p_limit),
        offset: p_offset * p_limit,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setPendingPermits(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );
  const [getAllApprovedPermitsDoc, { loading: approvedLoading }] = useLazyQuery(
    DOCUMENT_ALL_APPROVED_WORK_PERMITS,
    {
      variables: {
        limit: Number(a_limit),
        offset: a_offset * a_limit,
        checkerStatus: true,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setApprovedPermits(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );
  const [getAllRejectedPermitsDoc, { loading: rejectedLoading }] = useLazyQuery(
    DOCUMENT_ALL_APPROVED_WORK_PERMITS,
    {
      variables: {
        limit: Number(r_limit),
        offset: r_offset * r_limit,
        checkerStatus: false,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setRejectedPermits(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );

  const [allOrg, {}] = useLazyQuery(GET_ALL_ORGANIZATION, {
    variables: {
      limit: 1000,
      offset: 0,
    },
    onCompleted: (_data) => {
      setOrganizationData(_data?.organizations);
    },
  });

  const [getAssignedOrg, {}] = useLazyQuery(GET_ASSIGNED_ORGANIZATION_LIST, {
    variables: {
      empID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
    },
    onCompleted: (d) => {
      setAssignedOrgList(d.assigned_organization_reviewers);
    },
  });

  //request new data if not found
  useEffect(() => {
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        // allOrg();
        getDataFromServer();
        getNotAssignedPermits();
        countExpat();
        countNotAssigned();
        break;

      case roles.WP_DOC_CHECKER:
        // getAssignedOrg();
        getTotalAssignedPermitsPending();
        getTotalAssignedPermitsApproved();
        getTotalAssignedPermitsRejected();
        getAllPendingPermitsDoc();
        getAllApprovedPermitsDoc();
        getAllRejectedPermitsDoc();
        break;

      case roles.WP_FINANCE:
        getTotalAssignedPermitsPending();
        getTotalAssignedPermitsApproved();
        getTotalAssignedPermitsRejected();
        getAllPendingPermitsDoc();
        getAllApprovedPermitsDoc();
        getAllRejectedPermitsDoc();
        // allOrg();
        break;

      default:
        break;
    }
  }, [currentRole]);
  // =========================================================================

  const handleBankSlip = (c) => {
    const reviewedDocument =
      c?.expatriate_work_permit?.expatriate_work_permit_documents
        ?.filter(
          (e) =>
            e?.document_type?.id === BANK_SLIP_ID && e?.review_status === true
        )
        ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))[0];

    if (reviewedDocument) {
      return reviewedDocument;
    }

    return c?.expatriate_work_permit?.expatriate_work_permit_documents
      ?.filter((e) => e?.document_type?.id === BANK_SLIP_ID)
      ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
      .map((i) => i)[0];
  };

  const openModal = (e) => {
    console.log("slip", e);
    setShowModal(true);
    let files = handleBankSlip(e?.row?.original);
    setModalData({
      files: files?.files,
      id: files?.id,
      organization:
        e?.row?.original?.expatriate_work_permit?.organization?.name,
      name:
        e.row.original?.expatriate_work_permit?.expatriate?.first_name +
        " " +
        e?.row?.original?.expatriate_work_permit?.expatriate?.father_name,
      reference_number:
        e?.row?.original?.expatriate_work_permit?.reference_number,
    });
  };

  const handleClose = () => {
    setShowModal(false);
    //get applicant data
    // getAllAssignedPermits({ fetchPolicy: "network-only" });
  };

  const handlePopUp = (e) => {
    console.log("test pop up clicked!", e);
    setShow((prev) => !prev);
    setQueryName(e);
  };

  const handleReviewStatus = (c) => {
    // let r = c?.expatriate_work_permit?.expatriate_work_permit_documents
    //   .filter((i) => i?.document_type?.id === BANK_SLIP_ID)
    //   .map((j) => j?.review_status)[0];
    let r = handleBankSlip(c);
    // console.log(r);
    if (r?.review_status === true) {
      return "Verifed";
    }
    if (r?.review_status === false) {
      return "Rejected";
    }
    if (r?.review_status === null) {
      return "Pending";
    }
  };

  /**
   * @description handle refersh table
   */
  const handleRefershTable = async () => {
    console.log("refersh clicked");
    setOffset(0);
    if (currentRole !== roles.WP_DESK_HANDLER) {
      setAllAssignedPermit([]);
      if (currentTab === "pending") {
        await getAllPendingPermitsDoc({
          variables: { limit: limit, offset: 0 * limit },
          fetchPolicy: "network-only",
        });
      }
      if (currentTab === "approved") {
        await getAllApprovedPermitsDoc({
          variables: { limit: limit, offset: 0 * limit },
          fetchPolicy: "network-only",
        });
      }
    } else {
      setWorkPermits([]);
      await getDataFromServer({
        variables: { limit: limit, offset: 0 * limit },
        fetchPolicy: "network-only",
      });
    }
  };

  const checkVerify = (arr) => {
    let allVerified = false;

    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].verified) {
          allVerified = true;
          break;
        }
      }
    }

    return allVerified;
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <pre
        style={{
          fontSize: "10px",
        }}
      >
        <table className="w-11/12 mx-auto capitalize">
          <thead className="bg-[#F0F6FD]">
            <tr>
              <th className="text-left text-N60 p-2 py-4">filename</th>
              <th className="text-left text-N60 p-2 py-4">uploaded date</th>
              <th className="text-left text-N60 p-2 py-4">title</th>
              <th className="text-left text-N60 p-2 py-4">assigned to</th>
            </tr>
          </thead>
          <tbody className="alternate-bg text-base">
            {row?.original?.expatriate_work_permit_documents?.map((item) => (
              <tr>
                <td className="px-2 py-4 font-semibold">{item.title}</td>
                <td className="px-2 py-4 font-semibold">
                  <Moment format="YYYY/MM/DD">{item?.created_at}</Moment>
                </td>
                <td className="px-2 py-4 font-semibold">
                  <span className="bg-Prime90/40 border px-2 py-1 rounded border-prime50 text-primary">
                    {item?.properties?.name?.substring(0, 25)}
                  </span>
                </td>
                <td className="px-2 py-4 font-semibold">
                  <div className="flex flex-col text-xs">
                    {row?.original?.assigned_workpermit_reviewers?.map((i) => (
                      <span>{i?.employee?.user_info?.name}</span>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* <code>{JSON.stringify({ values: row?.original?.work_permit_request_documents }, null, 2)}</code> */}
      </pre>
    ),
    []
  );

  const handleAssign = (c) => {
    console.log(c);
    // Set loading state for the specific row
    setLoadingRows((prevLoadingRows) => [...prevLoadingRows, c]);
    console.log("rows:", loadingRows);
    console.log("rows:___", loadingRows.includes(c));
    console.log(setLoadingRows((prevLoadingRows) => [...prevLoadingRows, c]));

    assign({ variables: { id: c } }).then((re) => {
      if (re.errors) {
        toast.error("Permit not Assigned");
      } else {
        toast.success("Assigned for FInance and Doc checker");
        getDataFromServer({ fetchPolicy: "network-only" });
        setLoadingRows((prevLoadingRows) =>
          prevLoadingRows.filter((rowId) => rowId !== c)
        );
      }
    });
  };

  const handlePreviousPage = () => {
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        if (currentTab === "assigned") {
          if (offset > 0) {
            setOffset(offset - 1);
          }
        }
        if (currentTab === "not_assigned") {
          if (notAssignedOffset > 0) {
            setNotAssignedOffset(notAssignedOffset - 1);
          }
        }
        break;

      case roles.WP_DOC_CHECKER:
        if (offset > 0) {
          setOffset(offset - 1);
        }
        break;

      case roles.WP_FINANCE:
        if (offset > 0) {
          setOffset(offset - 1);
        }
        break;

      default:
        break;
    }
  };

  const handleNextPage = () => {
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        if (currentTab === "assigned") {
          setOffset(offset + 1); //the first offset
        }
        if (currentTab === "not_assigned") {
          setNotAssignedOffset(notAssignedOffset + 1);
        }
        break;

      case roles.WP_DOC_CHECKER:
        setOffset(offset + 1); //the first offset
        break;

      case roles.WP_FINANCE:
        setOffset(offset + 1); //the first offset
        break;
      default:
        break;
    }
  };

  const handleNextPending = () => {
    setPendingOffset(p_offset + 1);
  };
  const handlePreviousPending = () => {
    if (p_offset > 0) {
      setPendingOffset(p_offset - 1);
    }
  };
  const handleNextRejected = () => {
    setRejectedOffset(r_offset + 1);
  };
  const handlePreviousRejected = () => {
    if (r_offset > 0) {
      setRejectedOffset(r_offset - 1);
    }
  };
  const handleNextApproved = () => {
    setApprovedOffset(a_offset + 1);
  };
  const handlePreviousApproved = () => {
    if (a_offset > 0) {
      setApprovedOffset(a_offset - 1);
    }
  };

  const handlePageChangeP = (newO) => {
    if (selectedTab === "pending") {
      setPendingOffset(newO - 1);
    }
    if (selectedTab === "approved") {
      setApprovedOffset(newO - 1);
    }
    if (selectedTab === "rejected") {
      setRejectedOffset(newO - 1);
    }
  };

  const handlePageLimitP = (_limit) => {
    if (selectedTab === "pending") {
      setLimitPending(_limit);
    }
    if (selectedTab === "approved") {
      setLimitApproved(_limit);
    }
    if (selectedTab === "rejected") {
      setLimitRejected(_limit);
    }
  };

  const handlePageChange = (newOffset) => {
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        if (currentTab === "assigned") {
          setOffset(newOffset - 1);
        }
        if (currentTab === "not_assigned") {
          setNotAssignedOffset(newOffset - 1);
        }
        break;

      case roles.WP_DOC_CHECKER:
        setOffset(newOffset - 1);
        break;

      case roles.WP_FINANCE:
        setOffset(newOffset - 1);

      default:
        break;
    }
  };

  const handleSearch = (r) => {
    console.log("search value", r);
  };

  const handleSelectedFilterOption = (_value) => {
    setSelectedValue(_value);
  };

  // =======================================FIANNCE========================
  /**
   * @description verifi document Bank
   */
  const [verify_doc, { fin_data, loading: signInLoading }] = useMutation(
    VERIFY_EXPATRIATE_DOCUMENT,
    {
      onError(err) {
        const error = `${err}`.split(":").reverse()[0];
        console.log(error.trim());
      },
      onCompleted(d) {
        console.log(d);
      },
    }
  );

  const handleReject = async (w) => {
    console.log("rejecte document", w, modalData);
    await verify_doc({
      variables: {
        id: modalData?.id,
        review_status: false,
        review_note: w?.review_note_doc,
      },
    }).then(() => {
      // toast.SuccessToast('Finance Docuemnt Verified Successfully')
      toast.warn("Finance Document Rejected!");
      handleClose();
      setRejectModal(false);
    });
  };

  const handleVerify = async () => {
    console.log("Finance Docuemnt Verified Successfully", modalData?.id);
    await verify_doc({
      variables: { id: modalData?.id, review_status: true },
    }).then(() => {
      handleClose();
      toast.success("Finance Docuemnt Verified Successfully");
    });
  };

  // ---------------------------------------------------------------SEARCH----------------------------------
  const [searchExpatByOrg, {}] = useLazyQuery(EXPAT_BY_ORGANZATION, {
    variables: {
      limit: limit,
      offset: offset * limit,
    },
    onCompleted: (d) => {
      setWorkPermits(d.expatriate_work_permits);
    },
    onError: (er) => console.log(er),
  });

  const [getAssignedExpat, {}] = useLazyQuery(
    GET_SEARCH_ASSIGNED_WORK_PERMITS,
    {
      onCompleted: (d) => {
        setAllAssignedPermit(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );
  const onFilterExpat = (_docID) => {
    setWorkPermits([]);
    setAllAssignedPermit([]);
    if (currentRole === roles.WP_DESK_HANDLER) {
      searchExpatByOrg({
        variables: {
          orgID: _docID,
        },
      });
    } else {
      getAssignedExpat({
        variables: {
          orgID: _docID,
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
    }
  };

  const onResetFilter = () => {
    setSelectedValue(null);
    if (currentRole === roles.WP_DESK_HANDLER) {
      getDataFromServer();
    } else {
      getTotalAssignedPermitsPending();
      getTotalAssignedPermitsApproved();
      getTotalAssignedPermitsRejected();
      getAllPendingPermitsDoc();
      getAllApprovedPermitsDoc();
      getAllRejectedPermitsDoc();
    }
  };

  const handlePageLimit = (_limit) => {
    console.log(_limit);
    setLimit(Number(_limit));
  };

  const handleRejectModal = () => {
    setRejectModal((prev) => !prev);
  };

  // ========================================FILTER==========================
  const handleDocumentHandlerFilter = (e) => {
    if (e) {
      searchPermitDocumentChecker({
        variables: {
          searchVal: e?.filterValue,
          permit: WORK_PERMIT_TYPES.NEW,
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
    }
  };

  const handleFianceFilter = (e) => {
    console.log("filter: ",e);
    if (e) {
      searchFinance({
        variables: {
          searchVal: e?.filterValue,
          permit: WORK_PERMIT_TYPES.NEW,
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
    }
  };

  const handleEMRGeneratorHandlerFilter = (e) => {
    if (e) {
      searchPermitEMR({
        variables: {
          emr: e,
          permit: WORK_PERMIT_TYPES.NEW,
        },
      });
    }
  };

  const [searchRenewalPermit, {}] = useLazyQuery(SEARCH_WORK_PERMITS_FILTER, {
    variables: {
      permit: WORK_PERMIT_TYPES.NEW,
    },
    onCompleted: (res) => {
      setNotAssignedWorkPermits(res?.expatriate_work_permits);
      // setPendingPermits(res?.expatriate_work_permits);
    },
    onError: (er) => console.log(er),
  });

  const [searchPermitEMR, {}] = useLazyQuery(
    APPROVED_EXPATIATES_PERMITS_RENEWAL_FILTER,
    {
      onCompleted: (d) => {
        setPendingPermits(d?.expatriate_work_permits);
      },
      onError: (er) => console.log(er),
    }
  );

  /**
   * @access DOCUMENT CHECKER
   */
  const [searchPermitDocumentChecker, {}] = useLazyQuery(
    SEARCH_EXPATIATES_PERMITS_RENEWAL_FILTER_DOCUMENT_CHECKER,
    {
      onCompleted: (d) => {
        setPendingPermits(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => console.log(er),
    }
  );

  //search for Finance (filter)
  const [searchFinance, {}] = useLazyQuery(
    SEARCH_EXPATIATES_PERMITS_FILTER_DOCUMENT_CHECKER_FINANCE,
    {
      onCompleted: (d) => {
        setPendingPermits(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => console.log(er),
    }
  );

  const resetFilter = () => {
    // reset();
    if (currentRole === roles.WP_DESK_HANDLER) {
      getDataFromServer();
      getNotAssignedPermits();
      countExpat();
      countNotAssigned();
    }
    if (currentRole === roles.WP_EMR_GENERATOR) {
      getTotalAssignedPermitsPending();
      getTotalAssignedPermitsApproved();
      getTotalAssignedPermitsRejected();
      getAllPendingPermitsDoc();
      getAllApprovedPermitsDoc();
      getAllRejectedPermitsDoc();
    } else {
      getTotalAssignedPermitsPending();
      getTotalAssignedPermitsApproved();
      getTotalAssignedPermitsRejected();
      getAllPendingPermitsDoc();
      getAllApprovedPermitsDoc();
      getAllRejectedPermitsDoc();
    }
  };

  const handleFilter = (e) => {
    console.log("filter box data: ", e);
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        setCurrentTab("not_assigned");
        searchRenewalPermit({
          variables: {
            searchValue: e?.filterValue,
          },
        });
        break;
      case roles.WP_DOC_CHECKER:
        setSelectedTab("pending");
        handleDocumentHandlerFilter(e);
        break;
      case roles.WP_FINANCE:
        console.log("finance filter");
        handleFianceFilter(e);
        break;
      case roles.WP_EMR_GENERATOR:
        handleEMRGeneratorHandlerFilter(e?.filterValue);
        console.log("emr generator filter");
        break;

      default:
        break;
    }
  };

  return (
    <div className="">
      <FilterBox
        placeholder={`${
          currentRole === roles.WP_EMR_GENERATOR
            ? "First Name and passport"
            : currentRole === roles.WP_FINANCE
            ? "Reference Number, Tin Number and passport"
            : currentRole === roles.WP_DOC_CHECKER
            ? "Tin Number, Passport Number or First Name"
            : "First Name and Passport Number"
        }`}
        handleReset={resetFilter}
        handleFilterData={handleFilter}
      />

      {currentRole === roles.WP_DESK_HANDLER && (
        <div className="mb-5 text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-200">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                onClick={() => setCurrentTab("not_assigned")}
                className={`${
                  currentTab === "not_assigned"
                    ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                    : "hover:text-gray-600 hover:border-gray-300 border-transparent"
                } inline-block p-4 border-b-2 rounded-t-lg`}
              >
                Not Assigned
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setCurrentTab("assigned")}
                className={`${
                  currentTab === "assigned"
                    ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                    : "hover:text-gray-600 hover:border-gray-300 border-transparent"
                } inline-block p-4 border-b-2 rounded-t-lg`}
                aria-current="page"
              >
                Assigned
              </button>
            </li>
          </ul>
        </div>
      )}

      {/* <FilterAccordion
        data={
          currentRole === roles.WP_DESK_HANDLER
            ? organizationData
            : currentRole === roles.WP_FINANCE
            ? organizationData
            : assignedOrgList?.map((i) => i?.organization)
        }
        onFilter={onFilterExpat}
        onResetFilter={onResetFilter}
      /> */}

      <Can I={"action"} a={"filterTab"}>
        {() => (
          <div className="my-4 inline-flex rounded-md shadow-sm" role="group">
            <button
              type="button"
              onClick={() => setSelectedTab("pending")}
              className={`${
                selectedTab === "pending"
                  ? "bg-yellow30 text-white hover:opacity-75 border-yellow40 "
                  : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              } px-4 py-2 text-sm font-medium text-gray-900 border  rounded-l-lg `}
            >
              Pending
            </button>
            <button
              type="button"
              onClick={() => setSelectedTab("rejected")}
              className={`${
                selectedTab === "rejected"
                  ? "bg-red30 text-white hover:opacity-75 border-red20 "
                  : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              } px-4 py-2 text-sm font-medium text-gray-900 border `}
            >
              Rejected
            </button>
            <button
              type="button"
              onClick={() => setSelectedTab("approved")}
              className={`${
                selectedTab === "approved"
                  ? "bg-green30 text-white hover:opacity-75 border-green40 "
                  : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              } px-4 py-2 text-sm font-medium text-gray-900 border rounded-r-lg `}
            >
              Approved
            </button>
            {/* <button
              type="button"
              onClick={() => setSelectedTab("reupload")}
              className={`${
                selectedTab === "reupload"
                  ? "bg-green30 text-white hover:opacity-75 border-green40 "
                  : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              } px-4 py-2 text-sm font-medium text-gray-900 border rounded-r-lg `}
            >
              ReUpload
            </button> */}
          </div>
        )}
      </Can>

      {currentRole !== roles.WP_DESK_HANDLER && assginedWorkPermits && (
        <>
          {selectedTab === "pending" && (
            <Table
              columns={
                currentRole === roles.WP_FINANCE
                  ? ApplicantColumns.financeHandlerApplicantColumn(
                      handleReviewStatus,
                      openModal
                    )
                  : currentRole === roles.WP_DOC_CHECKER
                  ? ApplicantColumns.documentHandlerApplicantColumn(
                      navigate,
                      handleReviewStatus
                    )
                  : ApplicantColumns.deskHandlerApplicantColumn(
                      navigate,
                      handleAssign,
                      loadingRows,
                      handlePopUp
                    ) //to be replaced
              }
              tableData={pendingPermits}
              loading={pendingLoading}
              offset={p_offset}
              filterOptions={filterOptionTable}
              limit={p_limit}
              error={error}
              handleRefersh={handleRefershTable}
              totalPages={totalPendingAssignedPermit}
              handleNextPage={handleNextPending}
              handlePageChange={handlePageChangeP}
              handlePreviousPage={handlePreviousPending}
              handlePageLimit={handlePageLimitP}
              onSearchChange={handleSearch}
              onFilterChange={handleSelectedFilterOption}
              renderRowSubComponent={renderRowSubComponent}
              title={"Applicant:  Work Permit Applcants"}
              headerClassess="bg-primary"
              pageCount={pageCount}
            />
          )}
          {selectedTab === "rejected" && (
            <Table
              columns={
                currentRole === roles.WP_FINANCE
                  ? ApplicantColumns.financeHandlerApplicantColumn(
                      handleReviewStatus,
                      openModal
                    )
                  : currentRole === roles.WP_DOC_CHECKER
                  ? ApplicantColumns.documentHandlerApplicantColumn(
                      navigate,
                      handleReviewStatus
                    )
                  : ApplicantColumns.deskHandlerApplicantColumn(
                      navigate,
                      handleAssign,
                      loadingRows,
                      handlePopUp
                    ) //to be replaced
              }
              tableData={rejectedPermits}
              loading={rejectedLoading}
              offset={r_offset}
              filterOptions={filterOptionTable}
              limit={r_limit}
              error={error}
              handleRefersh={handleRefershTable}
              totalPages={totalRejectedAssignedPermit}
              handlePageChange={handlePageChangeP}
              handleNextPage={handleNextRejected}
              handlePreviousPage={handlePreviousRejected}
              handlePageLimit={handlePageLimitP}
              onSearchChange={handleSearch}
              onFilterChange={handleSelectedFilterOption}
              renderRowSubComponent={renderRowSubComponent}
              title={"Applicant:  Work Permit Applcants"}
              headerClassess="bg-primary"
              pageCount={pageCount}
            />
          )}
          {selectedTab === "approved" && (
            <Table
              columns={
                currentRole === roles.WP_FINANCE
                  ? ApplicantColumns.financeHandlerApplicantColumn(
                      handleReviewStatus,
                      openModal
                    )
                  : currentRole === roles.WP_DOC_CHECKER
                  ? ApplicantColumns.documentHandlerApplicantColumn(
                      navigate,
                      handleReviewStatus
                    )
                  : ApplicantColumns.deskHandlerApplicantColumn(
                      navigate,
                      handleAssign,
                      loadingRows,
                      handlePopUp
                    ) //to be replaced
              }
              tableData={approvedPermit}
              loading={approvedLoading}
              offset={a_offset}
              filterOptions={filterOptionTable}
              limit={a_limit}
              error={error}
              handleRefersh={handleRefershTable}
              totalPages={totalApprovedAssignedPermit}
              handleNextPage={handleNextApproved}
              handlePreviousPage={handlePreviousApproved}
              handlePageChange={handlePageChangeP}
              handlePageLimit={handlePageLimitP}
              onSearchChange={handleSearch}
              onFilterChange={handleSelectedFilterOption}
              renderRowSubComponent={renderRowSubComponent}
              title={"Applicant:  Work Permit Applcants"}
              headerClassess="bg-primary"
              pageCount={pageCount}
            />
          )}
          {/* <Table
            columns={
              currentRole === roles.WP_FINANCE
                ? ApplicantColumns.financeHandlerApplicantColumn(
                    handleReviewStatus,
                    openModal
                  )
                : currentRole === roles.WP_DOC_CHECKER
                ? ApplicantColumns.documentHandlerApplicantColumn(
                    navigate,
                    handleReviewStatus
                  )
                : ApplicantColumns.deskHandlerApplicantColumn(
                    navigate,
                    handleAssign,
                    loadingRows,
                    handlePopUp
                  ) //to be replaced
            }
            tableData={allassignedPermit}
            loading={approvedLoading}
            offset={offset}
            filterOptions={filterOptionTable}
            limit={limit}
            error={error}
            handleRefersh={handleRefershTable}
            totalPages={totalAssignedPermit}
            handleNextPage={handleNextPage}
            handlePageChange={handlePageChange}
            handlePreviousPage={handlePreviousPage}
            handlePageLimit={handlePageLimit}
            onSearchChange={handleSearch}
            onFilterChange={handleSelectedFilterOption}
            renderRowSubComponent={renderRowSubComponent}
            title={"Applicant:  Work Permit Applcants"}
            headerClassess="bg-primary"
            pageCount={pageCount}
          /> */}
        </>
      )}

      {currentRole === roles.WP_DESK_HANDLER && (
        <>
          {currentTab === "assigned" && (
            <Table
              columns={ApplicantColumns.deskHandlerApplicantColumn(
                navigate,
                handleAssign,
                loadingRows,
                handlePopUp
              )}
              tableData={workPermits}
              loading={loading}
              error={error}
              offset={offset}
              limit={limit}
              filterOptions={filterOptionTable}
              totalPages={totalExpatList}
              handleNextPage={handleNextPage}
              handlePageChange={handlePageChange}
              handlePreviousPage={handlePreviousPage}
              handleRefersh={handleRefershTable}
              handlePageLimit={handlePageLimit}
              onSearchChange={handleSearch}
              onFilterChange={handleSelectedFilterOption}
              renderRowSubComponent={renderRowSubComponent}
              title={"Applicant:  Work Permit Applcants"}
              headerClassess="bg-primary"
              pageCount={pageCount}
            />
          )}
          {currentTab === "not_assigned" && (
            <>
              <Table
                columns={ApplicantColumns.deskHandlerApplicantColumn(
                  navigate,
                  handleAssign,
                  loadingRows,
                  handlePopUp
                )}
                tableData={notAssignedWorkPermits}
                loading={notAssignedLoading}
                error={notAssignedError}
                offset={offset}
                limit={limit}
                filterOptions={filterOptionTable}
                totalPages={notAssignedTotal}
                handleNextPage={handleNextPage}
                handlePageChange={handlePageChange}
                handlePreviousPage={handlePreviousPage}
                handleRefersh={handleRefershTable}
                handlePageLimit={handlePageLimit}
                onSearchChange={handleSearch}
                onFilterChange={handleSelectedFilterOption}
                renderRowSubComponent={renderRowSubComponent}
                title={"Permit:  Not Assigned Work Permit Applicants"}
                headerClassess="bg-primary"
                pageCount={notAssignedTotal}
              />
            </>
          )}
        </>
      )}

      <div className="fixed top-0 left-0 right-0 overflow-hidden">
        {show ? (
          <BlackListModal id={queryName} handleClick={handlePopUp} />
        ) : (
          ""
        )}
      </div>

      {showModal && (
        <DocumentViewerDrawer
          selectedDocument={modalData}
          handleVerify={handleVerify}
          handleRejection={handleRejectModal}
          handleCloseDrawer={() => setShowModal(false)}
        />
      )}

      {rejectModal && (
        <RejectModal
          setShowDocumentModal={setRejectModal}
          title={"Reject Work permit Bank Slip"}
          handleRejectDocument={handleReject}
        />
      )}
    </div>
  );
};

export default authorize("view")(Applicant);
